import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";
import CONFIG from "src/config";
import {
  convertArrayToJson,
  getInObject,
  getNameFromPathName,
  getTodayDate,
} from "src/utils";
import { NotificationManager } from "react-notifications";
import { localStorageKeys } from "src/constants";

const INIT_STATE = {
  isLoading: false,
  error: false,
  channel: "",
  defaultChannelData: [],
  selectedDate: getTodayDate(),
  programTableList: [],
  padTableList: [],
  selectedFileList: [],
  showTableData: [],
  showTableKey: [],
  liveContent: [],
  customLiveContent: [],
  selectedLive: "",
  liveStreamStatus: {},
  currentItem: {},
  previewItem: "",
  selectedVideo: "",
  channels: {},
};

const slice = createSlice({
  name: "mainPage",
  initialState: INIT_STATE,
  reducers: {
    initState(state) {
      state.isLoading = false;
      state.error = false;
      state.channel = "";
      state.defaultChannelData = [];
      state.selectedDate = getTodayDate();
      state.programTableList = [];
      state.padTableList = [];
      state.selectedFileList = [];
      state.showTableData = [];
      state.showTableKey = [];
      state.liveContent = [];
      state.customLiveContent = [];
      state.selectedLive = "";
      state.liveStreamStatus = {};
      state.currentItem = {};
      state.previewItem = "";
      state.selectedVideo = "";
      state.channels = {};
    },
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getViewChanelsSuccess(state, action) {
      state.isLoading = false;
      state.defaultChannelData = action.payload;
      // state.defaultChannelData = [];
      if (state.channel === "") {
        state.channel = action.payload[0].name;
      }
    },

    getViewCurrentItemSuccess(state, action) {
      state.isLoading = false;
      state.channels = action.payload;
    },
    getProgramTableDataSuccess(state, action) {
      state.isLoading = false;
      state.programTableList = action.payload;
    },

    getPadTableDataSuccess(state, action) {
      state.isLoading = false;
      state.padTableList = action.payload;
    },

    getLiveContentSuccess(state, action) {
      state.isLoading = false;
      state.liveContent = action.payload;
    },

    getCustomLiveContentSuccess(state, action) {
      state.isLoading = false;
      state.customLiveContent = action.payload;
    },

    setChannel(state, action) {
      state.channel = action.payload;
    },

    getCurrentItemSuccess(state, action) {
      state.currentItem = action.payload.currentItem;
      state.previewItem = action.payload.previewItem;
    },

    setPadKey(state, action) {
      const data = getInObject(state.padTableList, action.payload);
      if (data) {
        state.showTableData = data.child;
        state.showTableKey = action.payload;
      } else {
        state.showTableData = [];
        state.showTableKey = [];
      }
    },

    setSelectedDate(state, action) {
      state.selectedDate = action.payload;
    },

    setSelectedFileList(state, action) {
      state.selectedFileList = action.payload;
    },

    loadLiveSuccess(state, action) {
      state.liveStreamStatus = action.payload.liveStreamStatus;
    },

    setLive(state, action) {
      state.selectedLive = action.payload;
    },
    setSelectedVideo(state, action) {
      state.selectedVideo = action.payload;
    },
    removePlaylistSuccess(state, action) {
      const removedIndex = state.programTableList.findIndex(
        (i) => i.id === action.payload
      );
      state.programTableList.splice(removedIndex, 1);
    },
  },
});

export const {
  endLoading,
  initState,
  setChannel,
  setPadKey,
  setSelectedFileList,
  setSelectedDate,
  setLive,
} = slice.actions;

export function getViewChanels() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONFIG.serverUrl, {
        params: {
          action: "viewChannels",
        },
      });
      if (
        response.data.status === "success" &&
        "contentStreams" in response.data &&
        response.data.contentStreams.length > 0
      ) {
        dispatch(
          slice.actions.getViewChanelsSuccess(response.data.contentStreams)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getViewCurrentItem(channel) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONFIG.serverUrl, {
        params: {
          action: "viewCurrentItem",
          channel,
        },
      });
      if (response.data.status === "success") {
        dispatch(slice.actions.getViewCurrentItemSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProgramTableData(channel, currentDate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONFIG.serverUrl, {
        params: {
          action: "viewPlaylists",
          channel,
          selected_date: currentDate,
        },
      });
      if (
        response.data.status === "success" &&
        "schedulePlaylists" in response.data
      ) {
        dispatch(
          slice.actions.getProgramTableDataSuccess(
            response.data.schedulePlaylists
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPadTableData(isReload = false) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // Check if local storage has the data
      const localData = localStorage.getItem(localStorageKeys.padTableData);
      if (!localData || isReload) {
        const response = await axios.get(CONFIG.serverUrl, {
          params: {
            action: "viewContent",
          },
        });
        const { contentFiles } = response.data;

        if (contentFiles) {
          const tableObject = convertArrayToJson(contentFiles, "name");
          await dispatch(slice.actions.getPadTableDataSuccess(tableObject));
          // Set local storage
          localStorage.setItem(localStorageKeys.padTableData, JSON.stringify(tableObject));
          // await dispatch(setPadKey([Object.keys(tableObject).sort()[0]]));
        }
      } else {
        // Update redux state from local storage
        const tableObject = JSON.parse(localData);
        dispatch(slice.actions.getPadTableDataSuccess(tableObject));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLiveContent() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONFIG.serverUrl, {
        params: {
          action: "viewLiveContent",
        },
      });
      const { contentStreams } = response.data;

      if (contentStreams) {
        dispatch(slice.actions.getLiveContentSuccess(contentStreams));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCustomLive() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.apiUrl}/sociallive/custom/all`);
      if (response.data) {
        dispatch(slice.actions.getCustomLiveContentSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removePlayList(pl) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(CONFIG.serverUrl, {
        action: "removeItems",
        schedulePlaylist: {
          id: pl.id,
          items: pl.items.map((i) => ({ id: i.id })),
        },
      });
      if (response.data.status === "success") {
        dispatch(slice.actions.removePlaylistSuccess(pl.id));
        //   NotificationManager.success("Successfully deleted");
      }
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCurrentItem({ channel, user, currentItemId, itemName }) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setSelectedVideo(itemName));
      const response = await axios.post(CONFIG.serverUrl, {
        action: "viewCurrentItem",
        channel,
        current_item_id: currentItemId,
      });
      let previewItem = "";
      if (user.vodFolder !== "") {
        previewItem = `${user.vodpreview}${user.vodFolder}/${itemName}/playlist.m3u8`;
      } else {
        previewItem = `${user.vodpreview}${itemName}/playlist.m3u8`;
      }
      dispatch(
        slice.actions.getCurrentItemSuccess({
          currentItem: response.data,
          previewItem,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addPlayList({
  channel,
  items,
  title,
  shuffle = false,
  priority = 1,
  time,
}) {
  return async (dispatch) => {
    let duration = 0;
    for (let item of items) {
      duration += parseInt(item.duration);
    }
    try {
      const request = {
        action: "addPlaylistexacttime",
        title,
        channel,
        schedulePlaylist: {
          items: items.map((element) => ({
            title: getNameFromPathName(
              element.name ? element.name : element.item
            ),
            item: element.name ? element.name : element.item,
            startfrom: 0,
            duration: -1,
            vod: true,
          })),
          channel,
          title: title,
          startTime: time,
          stopTime: time + duration,
          shuffle,
          priority,
        },
      };
      const response = await axios.post(CONFIG.serverUrl, request);
      if (response.data.status === "success") {
        NotificationManager.success("Successfully added");
        return response.data;
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function reorderPlayList({ playlistID, items, channel }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let ids = "";
      items.forEach((element, index) => {
        ids +=
          index + 1 < items.length
            ? `${element.id}:${index},`
            : `${element.id}:${index}`;
      });
      if (ids !== "") {
        const response = await axios.post(CONFIG.serverUrl, {
          action: "ReorderItems",
          playlistID,
          itemsID: ids,
        });
        if (response.data.status === "success") {
          NotificationManager.success("Successfully reordered");
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addItems(channel, position, startFrom, stopTime, items) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const request = {
        action: "addItems",
        type: position,
        items: items.map((element) => ({
          title: getNameFromPathName(element.name),
          item: element.name,
          startfrom: startFrom,
          duration: items.length > 1 ? parseInt(element.duration) : stopTime,
          vod: true,
        })),
        channel,
      };
      const response = await axios.post(CONFIG.serverUrl, request);
      if (response.data.status === "success") {
        NotificationManager.success("Successfully added");
      }
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addItemsWithName(
  channel,
  position,
  startFrom,
  stopTime,
  items
) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const request = {
        action: "addItems",
        type: position,
        items: items.map((element) => ({
          title: getNameFromPathName(element.name),
          item: element.name,
          startfrom: items.length > 1 ? parseInt(element.startfrom) : startFrom,
          duration: items.length > 1 ? parseInt(element.duration) : stopTime,
          vod: true,
        })),
        channel,
      };
      const response = await axios.post(CONFIG.serverUrl, request);
      if (response.data.status === "success") {
        NotificationManager.success("Successfully added");
      }
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addLive({
  channel,
  title,
  startTime,
  stopTime,
  items,
  selectedLive,
}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const request = {
        action: "addLiveStream",
        schedulePlaylist: {
          items: [
            {
              title: title,
              item: items.length > 0 ? items[0].item : selectedLive,
              startfrom: -2,
              duration: stopTime - startTime,
              vod: false,
            },
          ],
          startTime: startTime,
          stopTime: stopTime,
          channel,
          title: title,
          shuffle: false,
          priority: 100,
        },
      };

      const response = await axios.post(CONFIG.serverUrl, request);
      await dispatch(getLiveContent());
      if (response.data.status === "success") {
        NotificationManager.success(response.data.status);
      } else {
        NotificationManager.error(response.data.status);
      }
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addLivePull({ type, name, uri }) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${CONFIG.apiUrl}/livemanager`, {
        action: "addlivepull",
        type,
        name,
        uri,
      });
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLiveDetails() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${CONFIG.apiUrl}/livemanager/details`);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addCustomLive({ 
  title, 
  server_url, 
  channel, 
  stream_key, 
  start_at, 
  end_at, 
  sendSSL,
  authentication,
  user_name,
  password,
  live_code = null, 
}) {
  return async (dispatch) => {
    try {
      const bodyParam = {
        title,
        server_url,
        channel,
        stream_key,
        start_at,
        end_at,
        sendSSL,
        authentication,
        user_name,
        password,
      };
      let response = null;
      if (live_code === null) {
        response = await axios.post(`${CONFIG.apiUrl}/sociallive/custom/add`, bodyParam);
      } else {
        response = await axios.patch(`${CONFIG.apiUrl}/sociallive/custom/update/${live_code}`, bodyParam);
      }
      NotificationManager.success(response.data.message);
      // Get the updated custom live list
      dispatch(getCustomLive());
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCustomLiveStatus(liveInfo) {
  return async (dispatch) => {
    try {
      let params = {}
      if (liveInfo.running) {
        params.live_code = liveInfo.live_code;
        params.sendSSL = liveInfo.sendSSL || null;
      } else {
        params.live_code = liveInfo.live_code;
        params.start_at = liveInfo.start_at !== null ? `${liveInfo.start_at}` : null;
        params.end_at = liveInfo.end_at != null ? `${liveInfo.end_at}` : null;
        params.sendSSL = liveInfo.sendSSL || null;
      }
      const url = liveInfo.running ? `${CONFIG.apiUrl}/sociallive/custom/stop` : `${CONFIG.apiUrl}/sociallive/custom/start`;
      const response = await axios.post(url, params);
      NotificationManager.success(response.data.message);
      // Get the updated custom live list
      dispatch(getCustomLive());
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteCustomLive({ liveCode }) {
  return async (dispatch) => {
    try {
      const response = await axios.delete(`${CONFIG.apiUrl}/sociallive/custom/delete/${liveCode}`);
      if (response.data.success) {
        NotificationManager.success(response.data.message);
        await dispatch(getCustomLive());
      } else {
        NotificationManager.error(response.data.message);
      }
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function probeVideo({ src }) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${CONFIG.apiUrl}/livemanager`, {
        action: "probeVideo",
        src,
      });
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removeLivePull({ name }) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${CONFIG.apiUrl}/livemanager`, {
        action: "removelivepull",
        name: name.replace(".stream", ""),
      });
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function loadLiveStatus(livename) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${CONFIG.apiUrl}/livemanager`, {
        action: "liveStat",
        name: livename,
      });
      if (response.data.status === "success") {
        dispatch(slice.actions.loadLiveSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteVideoFile({ destPath }) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${CONFIG.apiUrl}/filemanager`, {
        params: {
          action: "delete",
          DestPath: destPath,
        },
      });
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createNewDirectory({ destPath }) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`${CONFIG.apiUrl}/filemanager`, {
        params: {
          action: "create",
          DestPath: destPath,
        },
      });
      if (response.data.status === "success") {
        // await dispatch(slice.actions.endLoading());
        NotificationManager.success(response.data.msg);
      } else {
        NotificationManager.error(response.data.msg);
      }
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function renameVideoFile({ destPath, sourcePath }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`${CONFIG.apiUrl}/filemanager`, {
        params: {
          action: "rename",
          DestPath: destPath,
          SourcePath: sourcePath,
        },
      });
      if (response.data.status === "success") {
        await dispatch(getPadTableData(true));
        NotificationManager.success(response.data.msg);
      } else {
        await dispatch(slice.actions.endLoading());
        NotificationManager.error(response.data.msg);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function copyDuplicateFile({ destPath, sourcePath }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`${CONFIG.apiUrl}/filemanager`, {
        params: {
          action: "copy",
          DestPath: destPath,
          SourcePath: sourcePath,
        },
      });
      if (response.data.status === "success") {
        await dispatch(getPadTableData(true));
      }
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function serverDoAction(action = "restart") {
  return async (dispatch) => {
    try {
      if (action === "restart") {
        const response = await axios.get(
          `${CONFIG.apiUrl}/actions/servers/${action}`
        );
        return response.data;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// Reducer
export default slice.reducer;
