import React from 'react'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function LoginCarousel({ props: { backImg, text } }) {
  return (
    <Box sx={{
      flex: "0 0 100%",
      position: "relative",
      minWidth: 0,
      height: "100vh", /* Ensure full height */
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end", /* 🔥 Push text to the bottom */
      alignItems: "center",
    }} >
      <img
        src={backImg}
        alt="background"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          objectFit: "cover", /* 🔥 Ensures the image covers the area */
          zIndex: -1,
        }}
      />
      <Typography
        variant="h5"
        textAlign="center"
        sx={{
          color: "white",
          fontFamily: "Zekton",
          paddingBottom: "80px", /* 🔥 Keeps text visible */
        }}>
        {text}
      </Typography>
    </Box>
  )
}