import React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Draggable } from "react-beautiful-dnd";
import EastIcon from '@mui/icons-material/East';
import IconDeleteSquare from "src/assets/image/ic_delete_square.png";

import { CheckBox } from "src/components";
import { makeTimeDuration } from "src/utils";
import { Box } from "@mui/material";

const getItemStyle = (isDragging, draggableStyle, vod) => ({
  // userSelect: "none",
  backgroundColor: isDragging && (vod ? "white" : "red"),
  ...draggableStyle,
});

const getTDStyle = (isDragging, draggableStyle, name) => ({
  width: isDragging ? (name === "item" ? "780px" : "100px") : "auto",
  textAlign: "center",
});

export default function DetailItem({
  item,
  index,
  clickedIdx,
  channels,
  onClick,
  onRemove,
  handleSelectItem,
}) {
  return (
    <Draggable key={item.id} index={index} draggableId={`${item.id}`}>
      {(provided, snapshot) => (
        <tr
          key={item.id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...(!snapshot.isDragging
              ? {
                backgroundColor:
                  clickedIdx === index ? "#8EC044" : "#FBF9F9",
              }
              : {}),
            ...(snapshot.isDragging
              ? getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style,
                item.vod
              )
              : {}),
            borderBottom: "2px solid #00000033",
          }}
          onClick={onClick}
        // sx={{
        //   backgroundColor: (theme) =>
        //     snapshot.isDragging ? theme.palette.background.neutral : 'transparent'
        //   // ...provided.draggableProps.style
        // }}
        >
          <td style={{ width: "100px" }}></td>
          <td className="center">
            <CheckBox
              onChange={handleSelectItem(item)}
              color="black"
            />
          </td>
          <td
            className="center"
            style={getTDStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
              "id"
            )}
          >

            {item.id}
          </td>
          <td
            className="center"
            style={getTDStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
              "item"
            )}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "5px", justifyContent: "center" }}>
              {channels.scheduleItem && item.id === channels.scheduleItem.id && (
                <EastIcon sx={{ color: "green" }} />
              )}
              <div className="wrap-content">
                {item.item}
              </div>
            </Box>
          </td>
          <td
            className="center"
            style={getTDStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
              "duration"
            )}
          >
            {makeTimeDuration(item.duration, 2)}
          </td>
          <td
            className="center"
            style={getTDStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
              "action"
            )}
          >
            <div className="actions">
              <Tooltip title="Delete" placement="top">
                <IconButton onClick={onRemove}>
                  {/* <HighlightOffIcon sx={{ color: "black" }} /> */}
                  <img src={IconDeleteSquare} alt="delete" />
                </IconButton>
              </Tooltip>
            </div>
          </td>
        </tr>
      )}
    </Draggable>
  );
}
