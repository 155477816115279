import { Typography } from "@mui/material";
import Modal from "src/components/Modal";
import moment from "moment";

export default function ShowCustomLiveDetailModal({ open, onClose, program }) {
  return (
    <Modal open={open} onClose={onClose} width="500px">
      <Typography variant="h5" sx={{ fontWeight: "bold", mt: "-1rem", textAlign: "center", mb: 2 }} gutterBottom>
        Custom Live Detail
      </Typography>
      <Typography variant="h6" gutterBottom>
        <b>Title:</b> {program.title}
      </Typography>
      <Typography variant="h6" gutterBottom>
        <b>Server URL:</b> {program.server_url}
      </Typography>
      <Typography variant="h6" gutterBottom>
        <b>Stream Key:</b> {program.stream_key}
      </Typography>
      {program.start_at && (
        <Typography variant="h6" gutterBottom>
          <b>Start At:</b> {moment(program.start_at).format("YYYY-MM-DD HH:mm:ss")}
        </Typography>
      )}
      {program.end_at && (
        <Typography variant="h6" gutterBottom>
          <b>End At:</b> {moment(program.end_at).format("YYYY-MM-DD HH:mm:ss")}
        </Typography>
      )}
      <Typography variant="h6" gutterBottom>
        <b>Send SSL:</b> {program.sendSSL ? "On" : "Off"}
      </Typography>
      <Typography variant="h6" gutterBottom>
        <b>Authentication:</b> {program.authentication ? "On" : "Off"}
      </Typography>
      {program.authentication && (
        <>
          <Typography variant="h6" gutterBottom>
            <b>User Name:</b> {program.auth_user_name}
          </Typography>
          <Typography variant="h6" gutterBottom>
            <b>Password:</b> {program.auth_password}
          </Typography>
        </>
      )}
    </Modal>
  );
}
