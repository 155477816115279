import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import PlayingDotIcon from 'src/assets/image/ic_playing_dot.png';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { useDispatch, useSelector } from "src/redux/store";
import {
  getLiveContent,
  removeLivePull,
  setLive,
} from "src/redux/slices/mainPage";
import useMainModal from "src/hooks/useMainModal";
import { NotificationManager } from "react-notifications";
import useAlertModal from "src/hooks/useAlertModal";
// import { setAlertName } from "src/redux/slices/alert";
import ReloadIcon from "src/assets/image/icon_reload.png";

export default function LiveTab() {
  const [selected, setSelected] = useState(null);

  const showMainModal = useMainModal();
  const { show: showAlert } = useAlertModal();
  const { mainPage } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (mainPage.liveContent.length && selected === null) {
      setSelected(mainPage.liveContent[0].name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainPage.liveContent]);

  useEffect(() => {
    if (selected !== null) {
      const handleAction = async () => {
        await dispatch(setLive(selected));
      };
      handleAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleDeleteLive = (livename) => {
    showAlert({
      name: "remove-live-pull",
      text: `Are you sure you want to delete the Live ${livename}`,
      onAccept: async () => {
        const response = await dispatch(removeLivePull({ name: livename }));
        if (response.status) {
          NotificationManager.success(response.message);
        } else {
          NotificationManager.warning(response.message);
        }
        await dispatch(getLiveContent());
        // await dispatch(setAlertName(""));
      },
    });
  };

  const handleReload = async () => {
    await dispatch(getLiveContent());
  };

  return (
    <Stack className="live-tab" spacing={3} mt={2}>
      <Stack justifyContent="end" direction="row" spacing={2}>
        <Box sx={{ px: "2rem" }}>
          <Tooltip title="Reload" placement="top">
            <IconButton onClick={handleReload}>
              <img src={ReloadIcon} alt="reload" height={20} />
            </IconButton>
          </Tooltip>
        </Box>
        <Button
          sx={{ flexGrow: 1 }}
          color="error"
          variant="outlined"
          startIcon={<AddOutlinedIcon />}
          onClick={() => {
            showMainModal({
              modalName: "add-live-modal",
              status: true,
            });
          }}
        >
          Add Live
        </Button>
      </Stack>
      <List component="div" disablePadding sx={{ paddingLeft: "10px" }}>
        {mainPage.liveContent.map((element, idx) => (
          <ListItem
            secondaryAction={
              element.name.endsWith(".stream") && (
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeleteLive(element.name)}
                >
                  <DeleteIcon
                    sx={{ color: element.name === selected ? "white" : "gray" }}
                  />
                </IconButton>
              )
            }
            key={idx}
            selected={element.name === selected}
            onClick={() => setSelected(element.name)}
            sx={{
              bgcolor: "white",
              "&.Mui-selected": {
                backgroundColor: "white",
              },
              "&.Mui-selected:hover": {
                backgroundColor: "lightgray",
              },
              borderRadius: "0.5rem",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
              border: element.name === selected ? "2px solid #8EC044" : "",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem", width: "100%" }}>
              <img src={PlayingDotIcon} alt="playing-dot" />
              <ListItemText primary={element.name.slice(0, 20)} />
              {element.name === selected && (<>
                <Box sx={{ flexGrow: 1 }} />
                <KeyboardArrowRightOutlinedIcon />
              </>)}
            </Box>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
