import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import Modal from "src/components/Modal";
import FormProvider, { RHFDateTimeForm, RHFTextField, RHFSwitch } from "src/components/HookForm";
import { useDispatch, useSelector} from "src/redux/store";
import { addCustomLive } from 'src/redux/slices/mainPage';
import useAuth from "src/hooks/useAuth";

export default function AddCustomLiveModal({ open, onClose, program }) {
  const dispatch = useDispatch();

  const {
    channel
  } = useSelector((state) => state.mainPage);
  const { user } = useAuth();

  const schema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    server_url: Yup.string().required("Server URL is required"),
    stream_key: Yup.string().required("Stream Key is required"),
    isScheduled: Yup.boolean(),
    start_at: Yup.mixed().when("isScheduled", {
      is: true,
      then: () => Yup.mixed().required("Start At is required"),
      otherwise: () => Yup.mixed().notRequired(),
    }),
    end_at: Yup.mixed().when("isScheduled", {
      is: true,
      then: () => Yup.mixed().required("End At is required"),
      otherwise: () => Yup.mixed().notRequired(),
    }),
    sendSSL: Yup.boolean(),
    authentication: Yup.boolean(),
    user_name: Yup.string().when("authentication", {
      is: true,
      then: () => Yup.string().required("User Name is required"),
      otherwise: () => Yup.string().notRequired(),
    }),
    password: Yup.string().when("authentication", {
      is: true,
      then: () => Yup.string().required("Password is required"),
      otherwise: () => Yup.string().notRequired(),
    }),
  });
  
  const defaultValues = {
    title: program?.title || "",
    server_url: program?.server_url || "",
    stream_key: program?.stream_key || "",
    isScheduled: program.start_at !== undefined && program.start_at !== null && program.end_at !== undefined && program.end_at !== null,
    start_at: program.start_at ? new Date(program.start_at) : null,
    end_at: program.start_at && program.end_at ? new Date(program.end_at) : null,
    sendSSL: program?.sendSSL || false,
    authentication: program?.authentication || false,
    user_name: program?.auth_user_name || "",
    password: program?.auth_password || "",
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const futureDate = new Date();
      futureDate.setFullYear(futureDate.getFullYear() + 100);

      const startAt = data.isScheduled && data.start_at ? data.start_at.getTime() : null;
      const endAt = data.isScheduled && data.end_at ? data.end_at.getTime() : futureDate.getTime();
      const sendSSLString = data.sendSSL ? "on" : "off";
      await dispatch(
        addCustomLive({
          title: data.title,
          server_url: data.server_url,
          channel: `${channel}${user.overlay ? "_output" : ""}`,
          stream_key: data.stream_key,
          start_at: startAt,
          end_at: endAt,
          sendSSL: sendSSLString,
          authentication: data.authentication,
          user_name: data.user_name,
          password: data.password,
          live_code: program?.live_code || null,
        })
      );
      reset(defaultValues);
      onClose();
    } catch (error) {
      console.error("-- Submit error --", error);
    }
  });

  const watchIsScheduled = watch("isScheduled");
  const watchAuthentication = watch("authentication");

  return (
    <Modal open={open} onClose={onClose} width="500px">
      <div className="subject text-center">
        {program.live_code ? "Edit" : "Add"} Custom Live
      </div>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack padding={2}>
          <RHFTextField name="title" label="Title" />
          <RHFTextField name="server_url" label="Server URL" sx={{ marginTop: "1rem" }} />
          <RHFTextField name="stream_key" label="Stream Key" sx={{ marginTop: "1rem" }} />
          <RHFSwitch name="isScheduled" label="Scheduled" sx={{ marginTop: "1rem" }} />
          {watchIsScheduled && (
            <Stack spacing={2} sx={{ marginTop: "-0.5rem" }}>
              <RHFDateTimeForm name="start_at" label="Start At" />
              <RHFDateTimeForm name="end_at" label="End At" />
            </Stack>
          )}

          <RHFSwitch name="sendSSL" label="Send SSL" sx={{ marginTop: "0.5rem" }} />
          <RHFSwitch name="authentication" label="Authentication" sx={{ marginTop: "0.5rem" }} />
          {watchAuthentication && (
            <Stack spacing={2} sx={{ marginTop: "-0.5rem" }}>
              <RHFTextField name="user_name" label="User Name" />
              <RHFTextField name="password" label="Password" />
            </Stack>
          )}

          <div className="actions" style={{ marginTop: "1rem" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? (<CircularProgress size={25} />) : "Submit"}
            </Button>
          </div>
        </Stack>
      </FormProvider>
    </Modal>
  );
}
