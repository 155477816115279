import { useState, useEffect, useMemo } from "react";
import {
  Box,
  Stack,
  IconButton,
  ListItem,
  Typography,
  LinearProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import Flow from "@flowjs/flow.js";
import MP4Box from "mp4box";

import { readablizeBytes } from "src/utils/getFileProperty";
import { millisToMinutesAndSeconds } from "src/utils";
import useAuth from "src/hooks/useAuth";

export default function VideoListItem({
  targetFile,
  destPath,
  handleCancelFile,
}) {
  const [progress, setProgress] = useState(0);
  const [videoTrack, setVideoTrack] = useState({});
  const [audioTrack, setAudioTrack] = useState({});
  const [statusMessage, setStatusMessage] = useState("Completed");
  const [isPaused, setIsPaused] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isValid, setIsValid] = useState(false);

  const { user } = useAuth();

  const { name, size } = targetFile;
  const chunkSize = 1024 * 1024 * 1; // its 1MB, increase the number measure in mb

  const flow = useMemo(() => {
    return new Flow({
      target: "https://acasapi.acan.group/api/upload",
      chunkSize,
      singleFile: true,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      query: {
        // DestPath: `/${destPath}`,
        DestPath: destPath,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetFile]);

  useEffect(() => {
    flow.addFile(targetFile);
    flow.on("fileProgress", (file) => {
      setProgress(parseFloat(Math.floor(file.progress() * 100)));
    });
    flow.on("fileSuccess", (file, message) => {
      setIsPaused(true);
      setStatusMessage(JSON.parse(message).status);
    });
    flow.on("fileError", (file, message) => {
      setStatusMessage(message);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetFile]);

  useEffect(() => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(targetFile);
    fileReader.addEventListener("load", (e) => {
      const buffer = fileReader.result;
      buffer.fileStart = 0;
      var mp4boxfile = MP4Box.createFile();
      mp4boxfile.onError = console.error;
      mp4boxfile.onReady = function (info) {
        setVideoTrack(info.tracks[0]);
        setAudioTrack(info.tracks[1]);
        const vtrack = info.tracks[0];
        if (user.transcoder === 0) {
          if (user.profile === "HD") {
            if (vtrack.track_height === 720) {
              setIsValid(true);
            } else {
              setErrorMessage(
                `This video resolution is ${vtrack.track_width} x ${vtrack.track_height}. Your playout is configured with ${user.profile}@1280x720. Please consider transcoding this file before uploading it`
              );
            }
          } else if (user.profile === "FHD") {
            if (vtrack.track_height === 1080) {
              setIsValid(true);
            } else {
              setErrorMessage(
                `This video resolution is ${vtrack.track_width} x ${vtrack.track_height}. Your playout is configured with ${user.profile}@1920x1080. Please consider transcoding this file before uploading it`
              );
            }
          } else if (user.profile === "SD") {
            if (vtrack.track_height === 576) {
              setIsValid(true);
            } else {
              setIsValid(false);
              setErrorMessage(
                `This video resolution is ${vtrack.track_width} x ${vtrack.track_height}. Your playout is configured with ${user.profile}@720x576. Please consider transcoding this file before uploading it`
              );
            }
          }
        } else {
          setIsValid(true);
          setErrorMessage("");
        }
      };
      mp4boxfile.appendBuffer(fileReader.result);
      mp4boxfile.flush();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetFile]);

  useEffect(() => {
    if (isValid) {
      setIsPaused(false);
      flow.upload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  const handleUploadToggler = (togglerName) => {
    if (togglerName === "cancel") {
      flow.cancel();
      setStatusMessage("");
      setProgress(0);
      handleCancelFile();
    }
    if (togglerName === "resume") {
      setIsPaused(false);
      flow.resume();
    }
    if (togglerName === "stop") {
      setIsPaused(true);
      flow.pause();
    }
  };

  return (
    <ListItem
      sx={{
        my: 1,
        py: 0.75,
        px: 2,
        borderRadius: 1,
        border: (theme) => `solid 1px ${theme.palette.divider}`,
        bgcolor: "background.paper",
      }}
    >
      <Stack direction="row" spacing={2} flexGrow={1}>
        <OndemandVideoIcon sx={{ width: 40, height: 40 }} />
        <Box flexGrow={1}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="subtitle2">{name}</Typography>
            <Typography variant="subtitle2">{readablizeBytes(size)}</Typography>
          </Stack>
          {videoTrack.type && audioTrack.type && (
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="subtitle2">
                Size: {`${videoTrack.track_width}x${videoTrack.track_height}`}
              </Typography>
              <Typography variant="subtitle2">
                Duration:{" "}
                {` ${millisToMinutesAndSeconds(videoTrack.movie_duration)}`}
              </Typography>
              <Typography variant="subtitle2">
                Codec: {` ${videoTrack.codec}`}
              </Typography>
              <Typography variant="subtitle2">
                Bitrate: {` ${Math.round(videoTrack.bitrate / 1000)}kbps`}
              </Typography>
              <Typography variant="subtitle2">
                Audio: {` ${audioTrack.codec}`}
              </Typography>
              <Typography variant="subtitle2">
                Audio Rate:{" "}
                {` ${Math.round(audioTrack.bitrate / 1000)}kbps ${
                  audioTrack.audio.sample_rate
                }`}
              </Typography>
            </Stack>
          )}
          <Typography variant="subtitle2" color="error">
            {errorMessage}
          </Typography>

          <Box sx={{ width: "100%" }}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
          {statusMessage === "success" ? (
            <Typography variant="caption">Successfully uploaded</Typography>
          ) : (
            <Typography variant="caption">{`Uploaded ${progress}%`}</Typography>
          )}
        </Box>
        {flow.files.length > 0 && (
          <Stack direction="row" spacing={1}>
            <IconButton
              edge="end"
              size="small"
              onClick={() => {
                if (isPaused) {
                  handleUploadToggler("resume");
                } else {
                  handleUploadToggler("stop");
                }
              }}
            >
              {isPaused ? <PlayArrowIcon /> : <PauseIcon />}
            </IconButton>
            <IconButton
              edge="end"
              size="small"
              onClick={() => handleUploadToggler("cancel")}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        )}
      </Stack>
    </ListItem>
  );
}
