import { useState, Fragment } from "react";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { CheckBox } from "src/components";
import { compareArray } from "src/utils";

export default function LinkItem({
  keyArray,
  title,
  checkedValues,
  linkInfo,
  selected,
  checkable,
  onClick,
  onCheck,
}) {
  const [open, setOpen] = useState(false);

  const handleClick = (keyArray) => {
    setOpen(!open);
    onClick(keyArray);
  };

  const checked = () => {
    const newKeyArray = keyArray.concat([title]);
    while (newKeyArray.length) {
      for (let keys of checkedValues) {
        if (compareArray(newKeyArray, keys)) return true;
      }
      newKeyArray.pop();
    }
    return false;
  };

  const newKeyArray = keyArray.concat([title]);
  const active = compareArray(newKeyArray, selected);
  let keys = Object.keys(linkInfo);

  return (
    <Fragment>
      <ListItemButton
        selected={active}
        onClick={() => handleClick(newKeyArray)}
        sx={{
          "&.Mui-selected": {
            backgroundColor: "var(--tr-selected-color)",
          },
          "&.Mui-selected:hover": {
            backgroundColor: "#8EC044",
          },
        }}
      >
        {checkable && (
          <CheckBox
            color={active ? "white" : "primary"}
            onChange={() => onCheck(newKeyArray)}
            checked={checked()}
          />
        )}
        <ListItemText primary={title} />
        {keys.length > 1 && <>{open ? <ExpandLess /> : <ExpandMore />}</>}
      </ListItemButton>
      {keys.length > 1 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ paddingLeft: "10px" }}>
            {keys.map(
              (element, idx) =>
                element !== "child" && (
                  <LinkItem
                    title={element}
                    keyArray={newKeyArray}
                    linkInfo={linkInfo[element]}
                    key={idx}
                    onClick={onClick}
                    selected={selected}
                    checkable={checkable}
                    onCheck={onCheck}
                    checkedValues={checkedValues}
                    // sx={{paddingLeft: `${5 * (idx + 1)}px`}}
                  />
                )
            )}
          </List>
        </Collapse>
      )}
    </Fragment>
  );
}
