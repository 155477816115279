import "./style.scss";

import { useState, useEffect } from "react";
import { Tabs, Tab, Box, IconButton } from "@mui/material";
import { NotificationManager } from "react-notifications";

import {
  Modal,
  ImageIcon,
  Clamp,
  TimeIcon,
  InputField,
} from "src/components";
import Button from "@mui/material/Button";
import TimingComponent from "../TimingComponent";
import PositionComponent from "../PositionComponent";
import FontComponent from "../FontComponent";
import TrasitionComponent from "../TransitionComponent";
import FormatComponent from "../FormatComponent";
import InputComponent from "../InputComponent";
import ShadowComponent from "../ShadowComponent";
import { CustomScrollContainer } from "src/container";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useSelector, useDispatch } from "src/redux/store";
import useAuth from "src/hooks/useAuth";
import {
  addOverlay,
  removeOverlay,
  setOverlay,
  updateOverlay,
  getOverlayList,
} from "src/redux/slices/overlay";
import useAlertModal from "src/hooks/useAlertModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default function EditOverlayModal({ open, onClose, overlayType, program }) {
  const { overlayActiveList, overlayStagingList, overlayWaitingList, overlay } = useSelector(
    (state) => state.overlay
  );
  const mainPage = useSelector((state) => state.mainPage);
  const [tabName, setTabName] = useState(0);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const { channel } = mainPage;
  const { user } = useAuth();
  const { show: showAlert } = useAlertModal();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!overlay.id) {
      setIsLoading(true);
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    await dispatch(
      getOverlayList({ channel: mainPage.channel, overlayType: "active" })
    );
    await dispatch(
      getOverlayList({ channel: mainPage.channel, overlayType: "waiting" })
    );
    setIsLoading(false);
  };

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  const handleAddOverlay = async (type) => {
    const programId = Object.keys(program).length !== 0 ? program.id : null;
    setLoading(true);
    if (image) {
      await dispatch(
        addOverlay({
          channel,
          overlay: { ...overlay, image },
          overlayType: type,
          programId
        })
      );
    } else {
      await dispatch(addOverlay({ channel, overlay, overlayType: type, programId }));
    }
    handleClose();
  };

  const handleUpdateOverlay = async (gettingType) => {
    setLoading(true);
    if (image) {
      await dispatch(
        updateOverlay({
          channel,
          overlay: { ...overlay, image },
          overlayType,
          refreshType: gettingType,
        })
      );
    } else {
      await dispatch(updateOverlay({ channel, overlay, overlayType, refreshType: gettingType }));
    }
    handleClose();
  };

  const submit = (type = "active") => {
    if (overlay.id) {
      if (type === "sendToOnAir") {
        handleAddOverlay("active");
      } else {
        // Check if overlay id exists in the active list or waiting list
        const index1 = overlayActiveList.findIndex((i) => i.id === overlay.id);
        const index2 = overlayWaitingList.findIndex((i) => i.id === overlay.id);
        const gettingType = index1 !== -1 ? "active" : index2 !== -1 ? "waiting" : "";
        handleUpdateOverlay(gettingType);
      }
    } else {
      const totalUsed = overlayActiveList.length + overlayWaitingList.length;
      if (totalUsed >= user.overlay_count) {
        NotificationManager.warning(
          "You already used all your available overlay slots." +
          " Please remove some overlay before adding a new one",
          "Warning",
          3000
        );
        return false;
      }
      handleAddOverlay("active");
    }
  };

  const sendToOnAir = (overlayId) => {
    showAlert({
      name: "send-to-onair-staging-overlay-item",
      text: "Are you sure you want to send this overlay to OnAir?",
      onAccept: async () => {
        setLoading(true);
        const response = await dispatch(
          addOverlay({
            channel: mainPage.channel,
            overlay: overlayStagingList.find((i) => i.id === overlayId),
            overlayType: "active",
          })
        );
        setLoading(false);
        if (response.status === "success") {
          NotificationManager.success("Successfully added");
        } else {
          NotificationManager.error("Failed");
        }
        handleClose();
      },
    });
  };

  const changeText = (evt) => {
    if (overlay.image) {
      dispatch(
        setOverlay({
          image: evt.target.value,
        })
      );
    } else {
      dispatch(
        setOverlay({
          text: evt.target.value,
        })
      );
    }
  };

  const handleRemoveOverlay = async () => {
    setLoading(true);
    await dispatch(
      removeOverlay({ channel, overlayId: overlay.id, overlayType })
    );
    setLoading(false);
    handleClose();
  };

  const handleClose = async () => {
    setLoading(false);
    await dispatch(setOverlay(null));
    onClose();
  };

  const handleChangeImage = (value) => {
    setImage(value);
  };

  if (tabName === 0 && !overlay.image)
    setTabName((prevTabName) => prevTabName + 1);
  if (tabName === 1 && !overlay.clock)
    setTabName((prevTabName) => prevTabName + 1);
  let imageValue = "tmp/logo.png";
  if (overlay.image) {
    if (overlay.image !== imageValue) {
      imageValue = overlay.image;
    }
  }
  let title;
  if (overlay.hasOwnProperty("clock")) {
    title = `${overlay.clock} ${overlay.timezone || ""}`;
  } else if (overlay.hasOwnProperty("image")) {
    title = overlay.image;
  } else {
    title = overlay.text;
  }
  return (
    <Modal
      open={open}
      className="edit-overlay-modal"
      loading={loading}
      onClose={() => handleClose()}
    >
      <div className="subject" style={{ marginBottom: "1rem", display: "flex", alignItems: "center" }}>
        {overlay.id && (
          <IconButton onClick={() => handleClose()} sx={{ marginRight: "1rem" }}>
            <ArrowBackIcon color="primary" />
          </IconButton>
        )}
        <div className="icon">
          {overlay.image ? (
            <ImageIcon />
          ) : overlay.clock ? (
            <TimeIcon />
          ) : (
            <Clamp />
          )}
        </div>
        <div className="title">
          {overlay.id ? "Edit Overlay" : "Add New Overlay"}
        </div>
      </div>
      <div className="content">
        <CustomScrollContainer>
          <div className="edit-content">
            <div className="subject">
              <div className="icon">
                {overlay.type === "text" && <Clamp />}
                {overlay.type === "image" && <ImageIcon />}
                {overlay.type === "time" && <TimeIcon />}
              </div>
              <InputField size="1.3rem" value={title} onChange={changeText} />
            </div>
            <div style={{ maxWidth: "80vw" }}>
              <Tabs
                value={tabName}
                onChange={(_, newValue) => setTabName(newValue)}
                indicatorColor="primary"
                textColor="primary"
                aria-label="full width tabs example"
                scrollButtons="auto"
                variant="scrollable"
              >
                <Tab
                  label="Input"
                  {...a11yProps(0)}
                  disabled={!overlay.image ? true : false}
                />
                <Tab
                  label="Format"
                  {...a11yProps(1)}
                  disabled={!overlay.clock || overlay.image ? true : false}
                />
                <Tab label="Timing" {...a11yProps(2)} />
                <Tab label="Position" {...a11yProps(3)} />
                <Tab
                  label="Font"
                  {...a11yProps(4)}
                  disabled={!overlay.style || overlay.image ? true : false}
                />
                <Tab
                  label="Transition"
                  {...a11yProps(5)}
                // disabled={overlay.image ? true : false}
                />
                <Tab
                  label="Shadow"
                  {...a11yProps(6)}
                // disabled={overlay.image ? true : false}
                />
              </Tabs>
            </div>
            <TabPanel value={tabName} index={0}>
              {overlay.image && <InputComponent onChange={handleChangeImage} />}
            </TabPanel>
            <TabPanel value={tabName} index={1}>
              {overlay.clock && <FormatComponent />}
            </TabPanel>
            <TabPanel value={tabName} index={2}>
              <TimingComponent program={program} />
            </TabPanel>
            <TabPanel value={tabName} index={3}>
              <PositionComponent />
            </TabPanel>
            <TabPanel value={tabName} index={4}>
              {overlay.style && <FontComponent />}
            </TabPanel>
            <TabPanel value={tabName} index={5}>
              <TrasitionComponent />
            </TabPanel>
            <TabPanel value={tabName} index={6}>
              <ShadowComponent />
            </TabPanel>
          </div>
        </CustomScrollContainer>
      </div>
      <Box className="actions" spacing={2}>
        {/* <button className="panda-button-staging" onClick={() => this.submit(2)} >
            {overlay.id ? "Update staging overlay" : "Add to staging"}
          </button> */}
        {overlay.id && overlayType === "staging" && (
          <Button onClick={() => sendToOnAir(overlay.id)} variant="contained" color="primary">Send to OnAir</Button>
        )}
        <Button onClick={() => submit()} variant="contained" color="primary" sx={{ marginLeft: "1rem" }} disabled={isLoading}>
          {overlay.id ? "Update overlay" : "Add overlay"}
        </Button>
        {overlay.id && (
          <Button onClick={handleRemoveOverlay} variant="contained" color="secondary" sx={{ marginLeft: "1rem" }}>Remove Overlay</Button>
        )}
        <Button
          variant="contained"
          onClick={() => {
            handleClose();
          }}
          color="error"
          sx={{ marginLeft: "1rem" }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
}
