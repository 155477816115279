import "./style.scss";
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import StopCircleIcon from '@mui/icons-material/StopCircle';

function PlayButton({ playing, color, onClick }) {
  return (
    <Box sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      gap: "8px",
      padding: "0.5rem 1rem",
      borderRadius: "5px",
      border: `2px solid ${color}33`,
    }}
      onClick={onClick}
    >
      {playing ? <StopCircleIcon sx={{ color: color, fontSize: 32 }} /> : <VideocamOutlinedIcon sx={{ color: color, fontSize: 32 }} />}
      <Typography sx={{ color }}>
        {playing ? "STOP" : "START"}
      </Typography>
    </Box>
  );
}
export default React.memo(PlayButton);
