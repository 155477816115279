import { useState, useEffect } from "react";
import RadioGroup from "@mui/material/RadioGroup";
import { Radio, Select } from "src/components";
import { useDispatch, useSelector } from "src/redux/store";
import { setOverlay } from "src/redux/slices/overlay";
import { allTimeZones } from "src/constants";
import { Stack } from "@mui/material";

export default function FormatComponent() {
  const getFormat = () => {
    if (overlay.clock) {
      const format = overlay.clock.split(":");
      if (format[0] === "h") {
        return "12";
      } else {
        return "24";
      }
    }
    return "24";
  }
  const { overlay } = useSelector((state) => state.overlay);
  const [value, setValue] = useState(overlay.clock || "HH:mm");
  const [timeFormat, setTimeFormat] = useState(getFormat());
  const [timezone, setTimeZone] = useState("UTC+00:00");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOverlay({ clock: value }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    dispatch(setOverlay({ timezone }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone]);

  const handleTimeZoneChange = (e) => {
    setTimeZone(e.target.value);
  };

  const handleTimeFormatChange = (e) => {
    setTimeFormat(e.target.value);
    if (e.target.value === "12") {
      setValue("h:mm");
    } else {
      setValue("HH:mm");
    }
  }

  const options12Hours = [
    { key: "h:mm a", label: "1:00 PM" },
    { key: "h:mm:ss a", label: "1:00:00 PM" }
  ];

  const options24Hours = [
    { key: "HH:mm", label: "13:00" },
    { key: "HH:mm:ss", label: "13:00:00" }
  ]

  return (
    <Stack direction="column" spacing={3}>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <div className="items">
          <div className="sub-title">Time Zone</div>
          <Select
            width="200px"
            value={timezone}
            onChange={handleTimeZoneChange}
            color="var(--main-txt-color)"
          >
            {allTimeZones.map((zone) => (
              <option key={zone} value={zone}>
                {zone}
              </option>
            ))}
          </Select>
        </div>
        <div className="items">
          <div className="sub-title">Time Format</div>
          <Select
            width="150px"
            value={timeFormat}
            onChange={(e) => handleTimeFormatChange(e)}
            color="var(--main-txt-color)"
          >
            <option value="12">12h format</option>
            <option value="24">24h format</option>
          </Select>
        </div>
      </Stack>
      <div className="items">
        <div className="sub-title">Display Format</div>
        <Select
          width="150px"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          color="var(--main-txt-color)"
        >
          {
            timeFormat === "12" ? options12Hours.map((option, index) => (
              <option key={index} value={option.key}>{option.label}</option>
            )) : options24Hours.map((option, index) => (
              <option key={index} value={option.key}>{option.label}</option>
            ))
          }
        </Select>
      </div>
      {/* <RadioGroup
        name="format"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      >
        <Radio value="HH:mm" label="HH:mm" color="var(--main-txt-color)" />
        <Radio value="HH:mm:ss" label="HH:mm:ss" color="var(--main-txt-color)" />

      </RadioGroup> */}
    </Stack>
  );
}
