import "./style.scss";

import { useState, useEffect } from "react";
import Slider from "@mui/material/Slider";
import { CheckBox, InputField } from "src/components";
import { useDispatch, useSelector } from "src/redux/store";
import { setOverlay } from "src/redux/slices/overlay";

export default function ShadowComponent() {
  const { overlay } = useSelector((state) => state.overlay);

  const [dropdown, setDropdown] = useState(() => {
    if (overlay.image) return false;
    else return true;
  });
  const [shadow, setShadow] = useState(
    overlay.shadow || { offsetX: 0, offsetY: 0, opacity: 0.7, radius: 0 }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOverlay({ shadow }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shadow]);

  const changeShadow = (field) => (evt) => {
    setShadow({ ...shadow, [field]: parseInt(evt.target.value) });
  };

  const { offsetX, offsetY, opacity, radius } = shadow;
  return (
    <div className="showdow-component">
      <div className="title">Shadow</div>
      <CheckBox
        checked={dropdown}
        onChange={(e) => setDropdown(e.target.checked)}
        color="var(--main-txt-color)"
      >
        Drop Shadow
      </CheckBox>
      {dropdown && (
        <div>
          <div>
            <InputField
              label="OffsetX"
              type="number"
              value={offsetX.toString()}
              onChange={changeShadow("offsetX")}
            />
            <InputField
              label="OffsetY"
              type="number"
              value={offsetY.toString()}
              onChange={changeShadow("offsetY")}
            />
          </div>
          <div>
            <InputField
              label="Radius"
              type="number"
              value={radius.toString()}
              onChange={changeShadow("radius")}
            />
          </div>
        </div>
      )}
      <div className="title">Opacity</div>
      <Slider
        min={0}
        max={1}
        step={0.1}
        valueLabelDisplay="on"
        value={opacity}
        className="m-t-50"
        onChange={(_, v) => setShadow({ ...shadow, opacity: v })}
        color="var(--main-txt-color)"
      />
    </div>
  );
}
