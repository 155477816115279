import { Fragment } from "react";

import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reOrder } from "src/utils/reOrder";
import { useDispatch, useSelector } from "src/redux/store";
import { setSelectedFileList } from "src/redux/slices/mainPage";
import PlayListActionGroup from "src/pages/main/PlayListActionGroup";
import Box from "@mui/material/Box";
import { Divider, Typography } from "@mui/material";

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: "5px",
  margin: `0 0 5px 0`,
  background: isDragging ? "grey" : "#fff",
  borderBottom: "2px solid #00000033",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  borderRadius: "0.5rem",
  background: isDraggingOver ? "lightblue" : "white",
  padding: 8,
  width: "100%",
});

export default function PadSelectedList() {
  const { selectedFileList } = useSelector((state) => state.mainPage);
  const dispatch = useDispatch();

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const items = reOrder(
      selectedFileList,
      result.source.index,
      result.destination.index
    );
    dispatch(setSelectedFileList(items));
  };

  const removeFileFromList = (item) => {
    dispatch(
      setSelectedFileList(selectedFileList.filter((i) => i.name !== item.name))
    );
  };

  return (
    <Fragment>
      <Stack justifyContent="end" sx={{
        bgcolor: "white",
        padding: "5px",
        borderRadius: "0.5rem",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
      }}>
        <PlayListActionGroup
          selectedFileList={selectedFileList}
          disabled={selectedFileList.length === 0}
        />
      </Stack>
      {selectedFileList.length !== 0 && (
        <Box sx={{
          borderRadius: "0.5rem",
          bgcolor: "white",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        }}>
          <Typography variant="h6" sx={{ p: "1rem", fontWeight: "bold" }}>Selected Files</Typography>
          <Divider />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="video-files-droppable-zone">
              {(provided, snapshot) => (
                <List
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  // style={{ backgroundColor: "grey" }}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {selectedFileList.map((item, idx) => (
                    <Draggable
                      draggableId={`${idx}_${item.name}`}
                      index={idx}
                      key={idx}
                    >
                      {(provided, snapshot) => (
                        <ListItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          secondaryAction={
                            <IconButton onClick={() => removeFileFromList(item)}>
                              <CloseIcon sx={{ color: "black", fontSize: 24 }} />
                            </IconButton>
                          }
                          sx={{ px: 1 }}
                        >
                          <ListItemText
                            title={item.name.slice(0, item.name.lastIndexOf("/"))}
                            primary={`${item.name
                              .slice(item.name.lastIndexOf("/") + 1)
                              .slice(0, 20)}...`}
                            sx={{ wordBreak: "break-word", maxWidth: 250 }}
                          />
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      )}
    </Fragment>
  );
}
