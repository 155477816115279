import "./style.scss";

import React, { Component } from "react";
import PropsTypes from "prop-types";
// import Button from "@mui/material/Button";
// import DialogContent from "@mui/material/DialogContent";
// import DialogActions from "@mui/material/DialogActions";
// import Dialog from "@mui/material/Dialog";

import themeColor from "src/constants/themeColor";
import MainLoadingScreen from "../Loading/MainLoadingScreen";
import DeleteIcon from "src/assets/image/ic_delete_square.png";
import { IconButton, Stack } from "@mui/material";

/**
 * Modal Component
 * @augments {Component<Props, State>}
 */
class Modal extends Component {
  static propsTypes = {
    open: PropsTypes.bool,
    color: PropsTypes.string,
    textColor: PropsTypes.string,
    width: PropsTypes.string,
    fullWidth: PropsTypes.bool,
    closeOutClick: PropsTypes.bool,
    onClose: PropsTypes.func,
  };

  state = {
    open: false,
  };

  makeStyle = () => {
    const open = this.props.hasOwnProperty("open")
      ? this.props.open
      : this.state.open;
    const { width, fullWidth, color, textColor } = this.props;
    return {
      display: open ? "flex" : "none",
      "--panda-modal-width": fullWidth ? "90%" : width || "auto",
      "--panda-modal-background-color": color || "white",
      "--panda-modal-text-color":
        textColor || themeColor["--default-font-color"],
      minHeight: 350,
    };
  };
  onClose = () => {
    if (this.props.hasOwnProperty("open")) {
      this.props.hasOwnProperty("onClose") && this.props.onClose();
    } else {
      this.setState({ open: false });
    }
  };

  render() {
    const open = this.props.hasOwnProperty("open")
      ? this.props.open
      : this.state.open;
    const { children, closeOutClick, className, loading } = this.props;
    return (
      <div
        className={`panda-modal ${className}`}
        style={this.makeStyle()}
        onClick={closeOutClick && this.onClose}
      >
        <div className="modal-content">
          <Stack>
            <div className="close-action" onClick={this.onClose}>
              <img src={DeleteIcon} alt="close" />
            </div>
            {!loading && open && children}
          </Stack>
          {/* {loading && <div className="loading-overlay"></div>} */}
          {loading && <MainLoadingScreen />}
        </div>
      </div>
    );
  }
}
export default Modal;

// export default function MuiDialog(props) {
//   const {
//     open,
//     color,
//     textColor,
//     loading,
//     fullWidth,
//     onClose,
//     children,
//     ...other
//   } = props;
//   return (
//     <Dialog
//       sx={{
//         "& .MuiDialog-paper": {
//           minWidth: 300,
//           minHeight: 300,
//           maxHeight: 750,
//           backgroundColor: color || "white",
//           textColor: textColor || themeColor["--default-font-color"],
//         },
//       }}
//       maxWidth="md"
//       fullWidth={fullWidth}
//       open={open}
//       {...other}
//     >
//       <DialogContent>
//         {loading ? <MainLoadingScreen /> : <Fragment>{children}</Fragment>}
//       </DialogContent>
//       <DialogActions>
//         <Button autoFocus onClick={onClose}>
//           Cancel
//         </Button>
//         <Button onClick={onClose}>Ok</Button>
//       </DialogActions>
//     </Dialog>
//   );
// }
