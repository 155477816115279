import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SmallIcon from "src/assets/image/ic_small_logo.png";

import { VideoPreview } from "src/components";
import useAuth from "src/hooks/useAuth";
import useMainModal from "src/hooks/useMainModal";
import Facebook from "./Facebook";
import Youtube from "./Youtube";
import { useDispatch, useSelector } from "src/redux/store";
import PadSelectedList from "./PadSelectedList";
import { IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getCustomLive, deleteCustomLive, updateCustomLiveStatus } from "src/redux/slices/mainPage";

export default function RightPanel({ tabIndex }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();

  const { selectedVideo, customLiveContent } = useSelector((state) => state.mainPage);
  const { user } = useAuth();
  const showMainModal = useMainModal();

  const handleMenuOpen = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
  };

  const handleShow = () => {
    showMainModal({ modalName: "show-custom-live-detail-modal", status: true, program: selectedItem });
    handleMenuClose();
  };

  const handleEdit = () => {
    showMainModal({ modalName: "add-custom-live-modal", status: true, program: selectedItem });
    handleMenuClose();
  };

  const handleDelete = () => {
    dispatch(deleteCustomLive({ liveCode: selectedItem.live_code }));
    handleMenuClose();
  };

  const openOverlay = (key) => {
    const warningExist = warningMsg();
    if (warningExist) {
      return false;
    }
    showMainModal({ modalName: key, status: true });
  };
  const warningMsg = () => {
    if (!user.overlay) {
      NotificationManager.warning(
        "You don't have Overlay Option. For more details, please contact our Customer Service at support@acan.email.",
        "Warning",
        3000
      );
      return true;
    }
    return false;
  };

  const handleAddCustomLive = () => {
    showMainModal({ modalName: "add-custom-live-modal", status: true });
  }

  const handleCustomLiveStatus = (liveInfo) => {
    dispatch(updateCustomLiveStatus(liveInfo));
  }

  useEffect(() => {
    // Run immediately
    dispatch(getCustomLive());
    // Run in every 2 minutes
    const facebookInterval = setInterval(() => {
      dispatch(getCustomLive());
    }, 120000);
    return () => {
      clearInterval(facebookInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="right-panel">
      <Stack spacing={2}>
        <Box
          sx={{
            minHeight: 168,
            borderRadius: "5px",
            backgroundColor: "#fff",
            px: "10px",
            pt: "10px",
            pb: "4px",
            // Add shadow
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
          }}
        >
          <VideoPreview id="top-right-preview-player" size={{ height: "auto", width: "100%" }} />
        </Box>
        {selectedVideo !== "" && (
          <Typography
            variant="caption"
            textAlign="center"
            sx={{
              wordBreak: "break-word",
            }}
          >
            {selectedVideo}
          </Typography>
        )}
        {tabIndex !== 1 ? (
          <Stack spacing={3}>
            <Box
              onClick={() => openOverlay("overlay-modal")}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                borderRadius: "5px",
                cursor: "pointer",
                p: 1
              }}>
              <Button

                sx={{ display: "flex", alignItems: "center" }}
              >
                <ListRoundedIcon />
                <Typography sx={{ color: "black", textTransform: "none", ml: 1 }}>Overlay List</Typography>
              </Button>

              <ChevronRightIcon color="primary" />
            </Box>

            <Box
              onClick={() => openOverlay("add-new-overlay-drawer")}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                borderRadius: "5px",
                cursor: "pointer",
                p: 1
              }}>
              <Button

                sx={{ display: "flex", alignItems: "center" }}
              >
                <AddIcon />
                <Typography sx={{ color: "black", textTransform: "none", ml: 1 }}>Overlay</Typography>
              </Button>

              <ChevronRightIcon color="primary" />
            </Box>
            <Facebook />
            <Youtube />
            {/* New part: Have to update */}
            {customLiveContent && customLiveContent.length > 0 && customLiveContent.map((item) => (
              <Stack key={item.live_code} direction="row" spacing={2}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0.5rem 1rem",
                  borderRadius: "0.5rem",
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                }}>
                <Typography textAlign="center">
                  {item.title}
                </Typography>
                <Button onClick={() => handleCustomLiveStatus(item)}>
                  {item.running ? (
                    <Stack direction="row" spacing={1}>
                      <StopCircleIcon sx={{ color: "#C70505" }} />
                      <Typography sx={{ color: "#C70505" }}>Stop</Typography>
                    </Stack>
                  ) : (
                    <Stack direction="row" spacing={1}>
                      <PlayCircleFilledWhiteIcon sx={{ color: "#053BC7" }} />
                      <Typography sx={{ color: "#053BC7" }}>Start</Typography>
                    </Stack>
                  )}
                </Button>
                <IconButton onClick={(e) => handleMenuOpen(e, item)}>
                  <MoreVertIcon sx={{ color: "#C70505" }} />
                </IconButton>
              </Stack>
            ))}
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={handleShow}>Show</MenuItem>
              <MenuItem onClick={handleEdit}>Edit</MenuItem>
              <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
            {/* New part: Have to update */}
            <Stack direction="column" spacing={2}
              sx={{
                alignItems: "start",
                padding: "1rem",
                borderRadius: "0.5rem",
                boxShadow: "0px 0px 10px 0px #98BF5633",
              }}>
              <Stack direction="row" spacing={1} alignContent="start">
                <img src={SmallIcon} alt="small-icon" />
                <Typography color="primary">
                  Custom Live
                </Typography>
              </Stack>
              <Button variant="outlined" fullWidth sx={{ textTransform: "none", color: "black" }} onClick={handleAddCustomLive}>
                Add Live Output
              </Button>
            </Stack>
          </Stack>
        ) : (
          <PadSelectedList />
        )}
      </Stack>
    </div>
  );
}
