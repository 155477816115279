import "./style.scss";

import { useEffect, useCallback, useRef, useState } from "react";
import PlayButton from "../PlayButton";
import { setYoutubeLiveStatus } from "src/redux/slices/youtube";
import { useDispatch, useSelector } from "src/redux/store";
import youtubeIcon from "src/assets/image/icon_youtube.png";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import IOSStyleSwitch from "src/components/Switch/iOSSwitch";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { TextField, InputAdornment } from "@mui/material";
import redKeyIcon from "src/assets/image/icon_key_red.png";

export default function Youtube() {
  const dispatch = useDispatch();
  const [streamKey, setStreamKey] = useState("");
  const [onScheduled, setOnScheduled] = useState(false);
  const { youtube, mainPage } = useSelector((state) => state);

  useEffect(() => {
    // Run immediately and every 2 minutes
    dispatch(setYoutubeLiveStatus(mainPage.channel, "", "status"));

    // Run in every 2 minutes
    const youtubeInterval = setInterval(() => {
      dispatch(setYoutubeLiveStatus(mainPage.channel, "", "status"));
    }, 120000);
    return () => {
      clearInterval(youtubeInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainPage.channel]);

  const handleKeyChange = useCallback(
    (e) => {
      setStreamKey(e.target.value);
    },
    []
  );

  const handleClick = (
    async () => {
      dispatch(
        setYoutubeLiveStatus(
          mainPage.channel,
          streamKey,
          !youtube.status ? "start" : "stop"
        )
      );
    }
  );

  return (
    <div className="youtube-live">
      <div className="title">
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <div className="icon">
            <img src={youtubeIcon} alt="youtube" />
          </div>
          Youtube
        </Box>
        <IconButton>
          <MoreVertIcon sx={{ color: "#C70505" }} />
        </IconButton>
      </div>
      <Box className="content" sx={{ my: 2 }}>
        <TextField
          sx={{
            boxShadow: "0px 0px 6px 6px rgba(246, 41, 41, 0.1)",
            // remove border line
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(152, 154, 159, 0.2)',
              },
              '&:hover fieldset': {
                borderColor: 'rgba(152, 154, 159, 0.2)',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'rgba(152, 154, 159, 0.2)',
              },
            },
          }}
          size="small"
          InputProps={{
            startAdornment: <InputAdornment position="start">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={redKeyIcon} alt="youtube" />
              </Box>
            </InputAdornment>,
          }}
          label="Stream Key"
          value={streamKey}
          onChange={handleKeyChange}
          fullWidth />
      </Box>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2} sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* <IOSStyleSwitch
          label="Scheduled"
          checked={onScheduled}
          onChange={() => setOnScheduled(!onScheduled)}
        /> */}
        <div>&nbsp;</div>
        <PlayButton onClick={handleClick} color={"#C70505"} playing={youtube.status} />
      </Stack>
    </div>
  );
}
