import "./style.scss";

import { useState, useEffect } from "react";
import { InputField, Select } from "src/components";
import { useDispatch, useSelector } from "src/redux/store";
import { setOverlay } from "src/redux/slices/overlay";

export default function TransitionComponent() {
  const { overlay } = useSelector((state) => state.overlay);
  const [transitionIn, setTransitionIn] = useState(() => {
    if (overlay.image) {
      return { type: "none", duration: 2, speed: 2, delay: 2 };
    }
    return (
      overlay.transitionIn || { type: "fade", duration: 2, speed: 2, delay: 2 }
    );
  });
  const [transitionOut, setTransitionOut] = useState(() => {
    if (overlay.image) {
      return { type: "none", duration: 2, speed: 2, delay: 2 };
    }
    return (
      overlay.transitionOut || { type: "fade", duration: 2, speed: 2, delay: 2 }
    );
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOverlay({ transitionIn }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transitionIn]);

  useEffect(() => {
    dispatch(setOverlay({ transitionOut }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transitionOut]);

  const changeTransitionIn = (field) => (evt) => {
    setTransitionIn({
      ...transitionIn,
      [field]:
        field === "speed" ? parseInt(evt.target.value) : evt.target.value,
    });
  };

  const changeTransitionOut = (field) => (evt) => {
    setTransitionOut({
      ...transitionIn,
      [field]:
        field === "speed" ? parseInt(evt.target.value) : evt.target.value,
    });
  };

  const changeTextDirection = (evt) => {
    setTransitionIn({ ...transitionIn, type: evt.target.value });
    setTransitionOut({ ...transitionOut, type: evt.target.value });
  };

  return (
    <div className="trasition-component">
      <div className="edit-panel">
        <div className="items">
          <div className="sub-title">Trasition In</div>
          <Select
            disabled={
              overlay.type === "news-bar" ||
              transitionIn.type === "ticker" ||
              transitionIn.type === "ticker-rtl" ||
              transitionIn.type === "ticker-up"
            }
            width="150px"
            label="Type"
            value={overlay.type === "news-bar" ? "tricker" : transitionIn.type}
            onChange={changeTransitionIn("type")}
            color="var(--main-txt-color)"
          >
            <option value="fade">fade</option>
            {overlay.type === "news-bar" && <option value="fade">fade</option>}
            <option value="none">none</option>
          </Select>
          <InputField
            width="150px"
            type="number"
            label="Speed"
            value={transitionIn.speed.toString()}
            onChange={changeTransitionIn("speed")}
          />
        </div>
        <div className="items">
          <div className="sub-title">Text Direction</div>
          {overlay.id ? (
            <Select
              disabled={!overlay.transitionIn.type.includes("ticker")}
              width="150px"
              value={transitionIn.type}
              onChange={changeTextDirection}
              color="var(--main-txt-color)"
            >
              <option value="ticker">Left to Right</option>
              <option value="ticker-rtl">Right to Left</option>
              <option value="ticker-up">Down to Up</option>
            </Select>
          ) : (
            <Select
              disabled={overlay.type !== "news-bar"}
              width="150px"
              value={transitionIn.type}
              onChange={changeTextDirection}
              color="var(--main-txt-color)"
            >
              <option value="ticker">Left to Right</option>
              <option value="ticker-rtl">Right to Left</option>
              <option value="ticker-up">Down to Up</option>
            </Select>
          )}
        </div>
        <div className="items">
          <div className="sub-title">Trasition Out</div>
          <Select
            disabled={
              overlay.type === "news-bar" ||
              transitionOut.type === "ticker" ||
              transitionOut.type === "ticker-rtl" ||
              transitionOut.type === "ticker-up"
            }
            width="150px"
            label="Type"
            value={overlay.type === "news-bar" ? "tricker" : transitionOut.type}
            onChange={changeTransitionOut("type")}
            color="var(--main-txt-color)"
          >
            <option value="fade">fade</option>
            <option value="none">none</option>
          </Select>
          <InputField
            width="150px"
            type="number"
            label="Speed"
            value={transitionOut.speed.toString()}
            onChange={changeTransitionOut("speed")}
          />
        </div>
      </div>
    </div>
  );
}
