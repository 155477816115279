import { styled } from "@mui/material/styles";
// import InputBase from "@mui/material/InputBase";
// import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";

const BootstrapInput = styled(TextField)(({ theme }) => ({
  "&.MuiFormControl-root.MuiTextField-root": {
    borderRadius: 4,
    border: "1px solid #D1D1D1",
  },
  "& .MuiOutlinedInput-root.MuiInputBase-root": {
    borderRadius: 4,
    border: "1px solid #D1D1D1",
  },
  "& .MuiOutlinedInput-root fieldset": {
    display: "none",
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-input.MuiInputBase-input": {
    color: "black",
    position: "relative",
    backgroundColor: "#fcfcfb",
    // border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "5px 12px",
    "&:focus": {
      //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      // borderColor: "#8EC044",
    },
  },
}));

export default function MuiInput({ sx, ...others }) {
  return <BootstrapInput {...others} sx={sx} />;
}
