import { useState, useEffect, Fragment } from "react";
import { NotificationManager } from "react-notifications";
import moment from "moment";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import VideoIcon from "src/assets/image/ic_video.png";
import TrashIcon from "src/assets/image/icon_delete.png";
import UploadIcon from "src/assets/image/icon_upload.png";
import AddListIcon from "src/assets/image/ic_add_list.png";
import RenameIcon from "src/assets/image/ic_rename.png";

import { DataTable } from "src/components";
import CheckBox from "src/components/Checkbox";
import {
  copyDuplicateFile,
  deleteVideoFile,
  getCurrentItem,
  getPadTableData,
  setSelectedFileList,
} from "src/redux/slices/mainPage";
import { useDispatch, useSelector } from "src/redux/store";
import {
  convertByteToMB,
  getNameFromPathName,
  getSubStringFromFirst,
  makeTimeDuration,
} from "src/utils";
import ExpandRow from "./ExpandRow";
import PlayListActionGroup from "../PlayListActionGroup";
import MuiSvgIconButton from "src/components/SvgButton/MuiSvgIconButton";
import UploadModal from "../Modals/UploadModal";
import ViewUploadModal from "../Modals/ViewUploadModal";
import RenameVideoFileModal from "../Modals/RenameVideoFileModal";
import useMainModal from "src/hooks/useMainModal";
import MainLoadingScreen from "src/components/Loading/MainLoadingScreen";
import useAuth from "src/hooks/useAuth";
import useAlertModal from "src/hooks/useAlertModal";
import ReloadIcon from "src/assets/image/icon_reload.png";
import GreenTrashIcon from "src/assets/image/ic_trash_green.png";
import CopyIcon from "src/assets/image/icon_copy.png";

export default function PadTabPanel({ search = "" }) {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showViewUpload, setShowViewUploadModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [fileToHandle, setFileToHandle] = useState(null);

  const { user } = useAuth();
  const showMainModal = useMainModal();
  const { show: showAlert } = useAlertModal();
  const dispatch = useDispatch();
  const mainPage = useSelector((state) => state.mainPage);
  const { showTableData, showTableKey, channel, selectedFileList, isLoading } =
    mainPage;

  useEffect(() => {
    setSelectedIndex(null);
  }, [showTableKey]);

  const handleSelectAll = (evt) => {
    if (evt.target.checked) {
      setSelectAll(true);
      dispatch(setSelectedFileList(showTableData));
    } else {
      setSelectAll(false);
      dispatch(setSelectedFileList([]));
    }
  };

  const padHeaders = [
    { name: "", width: "50px", align: "center" },
    {
      name: <CheckBox onChange={handleSelectAll} checked={selectAll} color={"white"} />,
      width: "50px",
      align: "center",
    },
    { name: "TITLE", align: "center" },
    { name: "SIZE", width: "", align: "center", class: "desktop" },
    { name: "CREATED", align: "center", class: "desktop" },
    { name: "DURATION", align: "center", class: "desktop" },
    { name: "ACTIONS", align: "center" },
  ];

  const activeList = (index, item) => {
    if (index !== selectedIndex) {
      setSelectedIndex(index);
      dispatch(
        getCurrentItem({
          channel,
          user,
          currentItemId: item.id,
          itemName: item.name,
        })
      );
    }
  };

  const handleSelectedList = (item) => (evt) => {
    if (evt.target.checked) {
      if (checkDuration(item)) {
        dispatch(setSelectedFileList(selectedFileList.concat(item)));
      }
    } else {
      const index = selectedFileList.indexOf(item);
      dispatch(
        setSelectedFileList(selectedFileList.filter((_, idx) => index !== idx))
      );
    }
  };

  const checkDuration = (item) => {
    if (parseInt(item.duration) === 0) {
      showAlert({
        name: "",
        text: `The duration of the file ${item.name} Duration is not correct. Please remove it from the selection to continue`,
        onAccept: () => { },
      });
      return false;
    } else {
      return true;
    }
  };

  const handleNotify = (response) => {
    if (response.status === "success") {
      NotificationManager.success(response.msg);
    } else {
      NotificationManager.error(response.msg);
    }
  };

  const handleDelete = (item) => {
    showAlert({
      name: "delete-video-file",
      text: "Are you sure you want to delete?",
      onAccept: async () => {
        const response = await dispatch(
          deleteVideoFile({ destPath: item.name })
        );
        if (response.status === "success") {
          await dispatch(getPadTableData(true));
        }
        handleNotify(response);
      },
    });
  };

  const handleBulkDelete = () => {
    showAlert({
      text: "bulk-delete-video-file",
      body: "Are you sure you want to delete the selected files?",
      onAccept: async () => {
        await Promise.all(
          selectedFileList.map(async (item) => {
            await dispatch(deleteVideoFile({ destPath: item.name }));
          })
        )
          .then(async (res) => {
            dispatch(setSelectedFileList([]));
            await dispatch(getPadTableData(true));
          })
          .catch((err) => {
            console.log(err);
          });
      },
    });
  };

  const handleCopyDuplicate = async (item) => {
    showAlert({
      name: "duplicate-video-file",
      text: "Are you sure you want to Duplicate this file?",
      onAccept: async () => {
        const sourcePath = item.name;
        const dotIndex = sourcePath.lastIndexOf(".");
        const response = await dispatch(
          copyDuplicateFile({
            sourcePath,
            destPath: `${sourcePath.slice(0, dotIndex)}_copy${sourcePath.slice(
              dotIndex
            )}`,
          })
        );
        handleNotify(response);
      },
    });
  };

  const handleRename = (item) => {
    setFileToHandle(item);
    setShowRenameModal(true);
  };

  const handleReload = async () => {
    setSelectAll(false);
    dispatch(setSelectedFileList([]));
    setSelectedIndex(0);
    await dispatch(getPadTableData(true));
  };

  if (isLoading) {
    return <MainLoadingScreen />;
  }

  // if (user.storageType === "vod")
  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Tooltip title="Reload" placement="top">
          <IconButton onClick={handleReload}>
            <img src={ReloadIcon} alt="reload" style={{ height: "1.8rem" }} />
          </IconButton>
        </Tooltip>

        <IconButton
          onClick={handleBulkDelete}
          disabled={selectedFileList.length === 0}
        >
          <Tooltip title="Bulk Delete" placement="top">
            <img src={GreenTrashIcon} alt="delete" />
            {/* <DeleteIcon
              sx={{
                color: selectedFileList.length === 0 ? "gray" : "#8EC044",
              }}
            /> */}
          </Tooltip>
        </IconButton>

        <Box flexGrow={1} />
        <Tooltip title="View Uploads" placement="top">
          <IconButton onClick={() => setShowViewUploadModal(true)}>
            <img src={CopyIcon} alt="copy" style={{ height: "1.8rem" }} />
          </IconButton>
        </Tooltip>
        <IconButton onClick={() => setShowUploadModal(true)} disabled={showTableKey.length === 0}>
          <img src={UploadIcon} alt="copy" style={{ width: "2.5rem" }} />
        </IconButton>
        <PlayListActionGroup
          disabled={selectedFileList.length === 0}
          selectedFileList={selectedFileList}
        />
      </Stack>
      <DataTable
        header={padHeaders}
        config={{
          headerColor: "black",
          headerTextColor: "white",
        }}
      >
        {showTableData.length ? (
          showTableData
            .filter((i) => i.name.toLowerCase().includes(search.toLowerCase()))
            .map(
              (list, index) =>
                index < 50 &&
                getSubStringFromFirst(getNameFromPathName(list.name))
                  .toLowerCase()
                  .includes(search.toLowerCase()) && (
                  <ExpandRow
                    key={index}
                    onClick={() => activeList(index, list)}
                    expandIconcolor={
                      index === selectedIndex ? "white" : "primary"
                    }
                    className={index === selectedIndex ? "active" : ""}
                    row={
                      <Fragment>
                        <td className="center">
                          <CheckBox
                            color={'black'}
                            checked={selectedFileList.includes(list)}
                            onChange={handleSelectedList(list)}
                          />
                        </td>
                        <td className="left">
                          <Stack
                            spacing={2}
                            direction="row"
                            alignItems="center"
                            className="wrap-content"
                          >
                            <img src={VideoIcon} alt="video" />
                            <Typography>
                              {getSubStringFromFirst(
                                getNameFromPathName(list.name)
                              )}
                            </Typography>
                          </Stack>
                        </td>
                        <td className="center desktop">{`${convertByteToMB(
                          list.size
                        )}MB`}</td>
                        <td className="center desktop">
                          {moment(Number(list.modified)).format("DD MMMM YYYY")}
                        </td>
                        <td className="center desktop">
                          {makeTimeDuration(list.duration, 2)}
                        </td>
                        <td className="center">
                          <div className="actions">
                            <IconButton onClick={() =>
                              showMainModal({
                                modalName: "add-playlist",
                                status: true,
                                items: [list],
                              })
                            }>
                              <img src={AddListIcon} alt="add_list" height="20" />
                            </IconButton>
                            <Tooltip title="Rename" placement="top">
                              <IconButton onClick={() => handleRename(list)}>
                                <img src={RenameIcon} alt="rename" height="20" />
                              </IconButton>
                            </Tooltip>
                            <MuiSvgIconButton
                              icon="add-time"
                              onClick={() => {
                                if (checkDuration(list)) {
                                  showMainModal({
                                    modalName: "add-time",
                                    status: true,
                                    items: [list],
                                  });
                                }
                              }}
                              textColor="black"
                            />
                            <IconButton onClick={() => handleCopyDuplicate(list)}>
                              <img src={CopyIcon} alt="ballot" height="22" />
                            </IconButton>
                            <IconButton>
                              <img src={UploadIcon} alt="ballot" height="20" />
                            </IconButton>
                            <IconButton onClick={() => handleDelete(list)}>
                              <img src={TrashIcon} alt="delete" height="20" />
                            </IconButton>
                          </div>
                        </td>
                      </Fragment>
                    }
                    list={list}
                  />
                )
            )
        ) : (
          <tbody>
            <tr>
              <td className="empty-data" colSpan="8">
                There is no video file in this folder..
              </td>
            </tr>
          </tbody>
        )}
      </DataTable>
      <UploadModal
        open={showUploadModal}
        onClose={() => {
          dispatch(getPadTableData(true));
          setShowUploadModal(false);
        }}
        sourcePath={`${showTableKey.join("/")}`}
      />
      {showViewUpload && (
        <ViewUploadModal
          open={showViewUpload}
          onClose={() => setShowViewUploadModal(false)}
        />
      )}
      {showRenameModal && (
        <RenameVideoFileModal
          open={showRenameModal}
          onClose={() => setShowRenameModal(false)}
          sourcePath={fileToHandle.name}
        />
      )}
    </Stack>
  );
  // return <FileBrowser />;
  // return null;
}
