import "./style.scss";

import { useEffect, useRef, useCallback, useState } from "react";
import PlayButton from "../PlayButton";
import { useDispatch, useSelector } from "src/redux/store";
import { setFacebookLiveStatus } from "src/redux/slices/facebook";
import fbIcon from "src/assets/image/icon_fb.png";
import blueKeyIcon from "src/assets/image/icon_key_blue.png";
import { Box, IconButton, Stack, TextField, Typography } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InputAdornment from '@mui/material/InputAdornment';
import IOSStyleSwitch from "src/components/Switch/iOSSwitch";

export default function Facebook() {
  const dispatch = useDispatch();
  const [streamKey, setStreamKey] = useState("");
  const [onScheduled, setOnScheduled] = useState(false);
  const { facebook, mainPage } = useSelector((state) => state);

  useEffect(() => {
    // Run immediately
    dispatch(setFacebookLiveStatus(mainPage.channel, "", "status"));
    // Run in every 2 minutes
    const facebookInterval = setInterval(() => {
      dispatch(setFacebookLiveStatus(mainPage.channel, "", "status"));
    }, 120000);
    return () => {
      clearInterval(facebookInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainPage.channel]);

  const onClick = async () => {
    dispatch(
      setFacebookLiveStatus(
        mainPage.channel,
        streamKey,
        !facebook.status ? "start" : "stop"
      )
    );
  };

  return (
    <div className="face-book-live">
      <div className="title">
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <div className="icon">
            <img src={fbIcon} alt="fb" />
          </div>
          Facebook Live
        </Box>
        <IconButton>
          <MoreVertIcon sx={{ color: "#053BC7" }} />
        </IconButton>
      </div>
      <Box className="content" sx={{ my: 2 }}>
        <TextField
          sx={{
            // Add shadow with color #296FF61A and apply to all borders
            boxShadow: "0px 0px 6px 6px rgba(41,111,246,0.1)",
            // remove border line
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(152, 154, 159, 0.2)',
              },
              '&:hover fieldset': {
                borderColor: 'rgba(152, 154, 159, 0.2)',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'rgba(152, 154, 159, 0.2)',
              },
            },
          }}
          size="small"
          InputProps={{
            startAdornment: <InputAdornment position="start">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={blueKeyIcon} alt="fb" />
              </Box>
            </InputAdornment>,
          }}
          label="Stream Key"
          value={streamKey}
          onChange={(e) => setStreamKey(e.target.value)}
          fullWidth />
      </Box>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2} sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* <IOSStyleSwitch
          label="Scheduled"
          checked={onScheduled}
          onChange={() => setOnScheduled(!onScheduled)}
        /> */}
        <div>&nbsp;</div>
        <PlayButton onClick={onClick} color={"#053BC7"} playing={facebook.status} />
      </Stack>
    </div>
  );
}
