import "./style.scss";
import "react-resizable/css/styles.css";

import { useState, useEffect } from "react";
import RadioGroup from "@mui/material/RadioGroup";
import { ResizableBox } from "react-resizable";
import Resizer from "react-image-file-resizer";

import { Radio, InputField } from "src/components";
import { dataURLtoFile } from "src/utils/UrlToFile";
import {
  // useDispatch,
  useSelector,
} from "src/redux/store";
// import { setOverlay } from "src/redux/slices/overlay";

export default function InputComponent({ onChange }) {
  const { overlay } = useSelector((state) => state.overlay);

  const [option, setOption] = useState("upload_image");
  const [width, setWidth] = useState(500);
  const [height, setHeight] = useState(300);
  const [value, setValue] = useState(
    overlay.image === "tmp/logo.png" ? null : overlay.image
  );
  const [file, setFile] = useState(null);
  // const dispatch = useDispatch();

  useEffect(() => {
    if (overlay.id && overlay.image.startsWith("data:")) {
      const imageFile = dataURLtoFile(overlay.image, "overlay-image.png");
      const blob = URL.createObjectURL(imageFile);
      const img = new Image();
      img.src = blob;
      img.onload = () => {
        setWidth(img.width);
        setHeight(img.height);
      };
      setFile(imageFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (value) {
      onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const getBase64Image = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const reader = new FileReader();
    await new Promise((resolve, reject) => {
      reader.onload = resolve;
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
    return reader.result;
  };

  const fileChangedHandler = async (event) => {
    if (event.target.files) {
      const url = URL.createObjectURL(event.target.files[0]);
      const base64 = await getBase64Image(url);
      URL.revokeObjectURL(url);
      setFile(event.target.files[0]);
      setValue(base64);
    }
  };

  const onResize = (event, { element, size, handle }) => {
    Resizer.imageFileResizer(
      file,
      size.width,
      size.height,
      "PNG",
      100,
      0,
      (uri) => {
        const convertedFile = dataURLtoFile(uri, "overlay_image.png");
        const blob = URL.createObjectURL(convertedFile);
        const img = new Image();
        img.src = blob;
        img.onload = () => {
          setWidth(size.width);
          setHeight(size.height);
          setValue(uri);
        };
      },
      "base64"
    );
  };

  return (
    <div className="input-component">
      <div className="title">Use Image Form</div>
      <div className="select-pandel">
        <RadioGroup
          name="imageType"
          value={option}
          onChange={(e) => {
            setOption(e.target.value);
            setWidth(500);
            setHeight(300);
            setValue(null);
          }}
        >
          <Radio value="online_url" label="URL" color="var(--main-txt-color)" />
          <Radio value="relative_path" label="Relative Path" color="var(--main-txt-color)" />
          <Radio value="upload_image" label="Upload Image" color="var(--main-txt-color)" />
        </RadioGroup>
      </div>
      {option === "online_url" && (
        <InputField label="URL" onChange={(e) => setValue(e.target.value)} />
      )}
      {option === "relative_path" && (
        <InputField
          label="Relative Path"
          onChange={(e) => setValue(e.target.value)}
        />
      )}
      {option === "upload_image" && (
        <>
          <div style={{ marginBottom: "20px" }}>
            <input type="file" onChange={fileChangedHandler} />
          </div>
          {value && (
            <ResizableBox width={width} height={height} onResize={onResize}>
              <img
                alt="base64Image"
                src={value}
                id="overlayImage"
                width={width}
                height={height}
              />
            </ResizableBox>
          )}
        </>
      )}
    </div>
  );
}
