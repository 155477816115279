import { Fragment, useEffect, useState } from "react";

import Button from "@mui/material/Button";
import TvIcon from "@mui/icons-material/Tv";

import useMainModal from "src/hooks/useMainModal";
import { useDispatch, useSelector } from "src/redux/store";
import MainLoadingScreen from "src/components/Loading/MainLoadingScreen";
import LiveVideoPreview from "../MainPage/VideoPreview/LiveVideoPreview";
import { probeVideo } from "src/redux/slices/mainPage";
import useAuth from "src/hooks/useAuth";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import { getLiveDetails } from "src/redux/slices/mainPage";

export default function LiveTabPanel() {
  const [firstStreamData, setFirstStreamData] = useState({});
  const [secondStreamData, setSecondStreamData] = useState({});
  const [formatData, setFormatData] = useState({});
  const [details, setDetails] = useState({});

  const { liveContent, selectedLive, defaultChannelData, isLoading } =
    useSelector((state) => state.mainPage);

  const showMainModal = useMainModal();
  const dispatch = useDispatch();
  const { user } = useAuth();

  useEffect(() => {
    if (selectedLive !== "") {
      const getData = async () => {
        const response = await dispatch(
          probeVideo({
            src: `${user.livepreview}/${defaultChannelData[0].appName}/${selectedLive}`
              .replace("https", "rtmp")
              .replace("443", "1935"),
          })
        );
        if (response.streams && response.streams.length > 2) {
          setFirstStreamData(response.streams[1]);
          setSecondStreamData(response.streams[2]);
          setFormatData(response.format);
        }
      };
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLive]);

  useEffect(() => {
    // Get live details as asynchronusly
    getDetails();
  }, []);

  const getDetails = async () => {
    try {
      const response = await dispatch(getLiveDetails());
      setDetails(response);
    } catch (error) {
      console.log("=== Error ===", error);
    }
  }

  return (
    <div className="live-component">
      {!isLoading ? (
        <Box>
          <Stack direction={{ xs: "column", lg: "row" }} spacing={3}>
            <Box sx={{ width: { xs: "100%", lg: "60%" } }}>
              {selectedLive !== "" ? (
                <LiveVideoPreview
                  id="live-video-preview"
                  size={{ width: "100%", height: "460px" }}
                />
              ) : (
                <div className="no-preview">There is no live contents.</div>
              )}
            </Box>
            <Stack direction="column" spacing={1} px={3} sx={{ width: { xs: "100%", lg: "40%" } }}>
              <Typography variant="h6">VIDEO DETAILS</Typography>
              <Divider />
              <Stack direction="column" spacing={5} px={3} py={2}>
                <Box>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body1" fontWeight="bold">
                      Video
                    </Typography>
                    <Typography variant="body1" sx={{ opacity: 0.7 }}>
                      : bitrate :
                    </Typography>
                    <Typography variant="body1">
                      {formatData.bit_rate && (
                        <span>{Math.round(formatData.bit_rate / 1000)}</span>
                      )}
                    </Typography>
                  </Stack>

                  <Stack direction="row" spacing={1}>
                    <Typography variant="body1" sx={{ opacity: 0.7 }}>
                      Codec:
                    </Typography>
                    <Typography variant="body1">
                      {firstStreamData.codec_name || ""}
                    </Typography>
                  </Stack>

                  <Stack direction="row" spacing={1}>
                    <Typography variant="body1" sx={{ opacity: 0.7 }}>
                      Resolution:
                    </Typography>
                    <Typography variant="body1">
                      {firstStreamData.coded_width && (
                        <span>{`${firstStreamData.coded_width}x${firstStreamData.coded_height}`}</span>
                      )}
                    </Typography>
                  </Stack>

                  <Stack direction="row" spacing={1}>
                    <Typography variant="body1" sx={{ opacity: 0.7 }}>
                      Quality:
                    </Typography>
                    <Typography variant="body1">
                      FHD
                    </Typography>
                  </Stack>
                </Box>

                <Box>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body1" fontWeight="bold">
                      Audio
                    </Typography>
                    <Typography variant="body1" sx={{ opacity: 0.7 }}>
                      : bitrate :
                    </Typography>
                    <Typography variant="body1">
                      {formatData.bit_rate && (
                        <span>{Math.round(formatData.bit_rate / 1000)}</span>
                      )}
                    </Typography>
                  </Stack>

                  <Stack direction="row" spacing={1}>
                    <Typography variant="body1" sx={{ opacity: 0.7 }}>
                      Codec:
                    </Typography>
                    <Typography variant="body1">
                      {secondStreamData.codec_name && `${secondStreamData.codec_name.toUpperCase()}.${secondStreamData.profile}`}
                    </Typography>
                  </Stack>

                  <Stack direction="row" spacing={1}>
                    <Typography variant="body1" sx={{ opacity: 0.7 }}>
                      Sample rate:
                    </Typography>
                    <Typography variant="body1">
                      {`${secondStreamData.sample_rate}`}
                    </Typography>
                  </Stack>
                </Box>
                <Button
                  color="error"
                  variant="contained"
                  disabled={liveContent.length === 0}
                  startIcon={<TvIcon />}
                  onClick={() =>
                    showMainModal({ modalName: "go-live-modal", status: true })
                  }
                  sx={{ width: "180px" }}
                >
                  Go Live
                </Button>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction={{ xs: "column", lg: "row" }} spacing={3} sx={{ mt: "5rem" }}>
            <Box>
              <Typography variant="h6" fontWeight="bold" sx={{ mb: "1rem" }}>RTMP Live Input details:</Typography>
              <Typography variant="body1">
                Server URL: <b>{details.server_url || ""}</b>
              </Typography>
              <Typography variant="body1">
                Stream Key: <b>{details.stream_key || ""}</b>
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" fontWeight="bold" sx={{ mb: "1rem" }}>Recommended Live Signal specs:</Typography>
              <Box
                rowGap={3}
                marginTop={3}
                columnGap={5}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(1, 1fr)',
                  md: 'repeat(1, 1fr)',
                  lg: 'repeat(1, 1fr)',
                  xl: 'repeat(2, 1fr)',
                }}
              >
                <Box>
                  <Typography variant="body1">
                    VIDEO: <b>{details.resolution || ""}</b>
                  </Typography>
                  <Typography variant="body1">
                    BITRATE: <b>{details.bitrate || ""}</b>
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1">
                    AUDIO: <b>{details.audio || ""}</b>
                  </Typography>
                  <Typography variant="body1">
                    SAMPLE RATE: <b>{details.sample_rate || ""}</b>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Stack>
        </Box>
      ) : (
        <MainLoadingScreen />
      )}
    </div>
  );
}
