import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import Stack from "@mui/material/Stack";
import { DateTimePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

export default function RHFDateTimeForm({ format, label, name }) {
  const { control } = useFormContext();

  return (
    <Stack spacing={1.5}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <DateTimePicker
            {...field}
            inputFormat={format || "dd.MM.yyyy HH:mm"}
            sx={{ bgcolor: 'white' }}
            onChange={(date) => {
              field.onChange(date);
            }}
            // render input with default MUI text field
            renderInput={(params) => (
              <TextField {...params} label={label} sx={{ bgcolor: 'white' }} error={!!error} helperText={error?.message} />
            )}
          />
        )}
      />
    </Stack>
  );
}

RHFDateTimeForm.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  format: PropTypes.string,
};
