import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";
import CONFIG from "src/config";
import { NotificationManager } from "react-notifications";

const initialState = {
  isLoading: false,
  error: "",
  overlayActiveList: [],
  overlayWaitingList: [],
  overlayStagingList: [],
  overlay: {},
  overlayStatus: false,
};

const slice = createSlice({
  name: "overlay",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getActiveOverlaySuccess(state, action) {
      state.isLoading = false;
      state.overlayActiveList = action.payload;
    },

    getWaitingOverlaySuccess(state, action) {
      state.isLoading = false;
      state.overlayWaitingList = action.payload;
    },

    getStagingOverlaySuccess(state, action) {
      state.isLoading = false;
      state.overlayStagingList = action.payload;
    },

    setOverlay(state, action) {
      if (action.payload === null) {
        state.overlay = {};
      } else {
        state.overlay = { ...state.overlay, ...action.payload };
      }
    },

    addOverlaySuccess(state) {
      state.overlay = {};
    },

    setOverlayStatus(state, action) {
      state.overlayStatus = action.payload;
    }
  },
});

export const { setOverlay } = slice.actions;

export function getOverlayList({ channel, overlayType }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(CONFIG.overlayUrl, {
        action:
          overlayType === "active"
            ? "listallactiveoverlays"
            : overlayType === "waiting"
            ? "listallwaitingoverlays"
            : "liststageoverlays",
        instruction: {},
        relativeTo: {
          scheduleStream: channel,
        },
      });
      if (response.data && Array.isArray(response.data)) {
        if (overlayType === "active") {
          dispatch(slice.actions.getActiveOverlaySuccess(response.data));
        } else if (overlayType === "waiting") {
          dispatch(slice.actions.getWaitingOverlaySuccess(response.data));
        } else {
          dispatch(slice.actions.getStagingOverlaySuccess(response.data));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setOnOffOverlay(channel, checked) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(CONFIG.overlayUrl, {
        action: "onoffoverlay",
        streamName: channel + "_output",
        enabled: checked,
      });
      if (response.data) {
        NotificationManager.success(response.data.msg);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOverlayStatus({ channel }) {
  return async (dispatch) => {
    try {
      const response = await axios.post(CONFIG.overlayUrl, {
        action: "overlaystate",
        streamName: channel + "_output"
      });
      if (response.data) {
        const status = response.data.state === "enabled";
        dispatch(slice.actions.setOverlayStatus(status));
        return status;
      }
      return false;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function addOverlay({
  channel,
  overlay,
  overlayType,
  programId = null,
}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(CONFIG.overlayUrl, {
        action:
          overlayType !== "staging" ? "createoverlay" : "createoverlaystage",
        instruction: overlay,
        relativeTo: {
          scheduleStream: channel,
        },
        programId,
      });
      if (response.data.status) {
        dispatch(slice.actions.addOverlaySuccess());
      }
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateOverlay({ channel, overlay, overlayType, refreshType }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(CONFIG.overlayUrl, {
        action:
          overlayType !== "staging" ? "updateoverlay" : "updateoverlaystage",
        id: overlay.id,
        instruction: overlay,
        relativeTo: channel,
      });
      if (response.data.status) {
        dispatch(slice.actions.addOverlaySuccess());
        if (overlayType === "staging") {
          dispatch(getOverlayList({ channel, overlayType: "staging" }));
        } else {
          if (refreshType) {
            dispatch(getOverlayList({ channel, overlayType: refreshType }));
          }
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removeOverlay({ channel, overlayId, overlayType }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(CONFIG.overlayUrl, {
        action:
          overlayType !== "staging"
            ? "deletesingleoverlay"
            : "deleteoverlaystage",
        id: overlayId,
        instruction: {},
        relativeTo: channel,
      });
      if (response.data) {
        if (response.data.status === "success") {
          NotificationManager.success(response.data.msg);
        } else {
          NotificationManager.warning(response.data.msg);
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function toggleLockOverlay({ channel, overlayId }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(CONFIG.overlayUrl, {
        action: "togglelockoverlaystage",
        id: overlayId,
        instruction: {},
        relativeTo: channel,
      });
      if (response.data) {
        if (response.data.status === "success") {
          NotificationManager.success(response.data.msg);
        } else {
          NotificationManager.warning(response.data.msg);
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export default slice.reducer;
