import { useState, useEffect, Fragment, useCallback } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';
import QueuePlayNextOutlinedIcon from '@mui/icons-material/QueuePlayNextOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SearchIcon from "@mui/icons-material/Search";
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import ProgramsComponent from "./ProgramTab";
import PadTab from "./PadTab";
import LiveTab from "./LiveTab";
import ProgramTabPanel from "../MiddlePanel/ProgramTabPanel";
import PadTabPanel from "../MiddlePanel/PadTabPanel";
import LiveTabPanel from "../MiddlePanel/LiveTabPanel";
import RightPanel from "../RightPanel";

import { useDispatch, useSelector } from "src/redux/store";
import {
  getLiveContent,
  getPadTableData,
  getProgramTableData,
  setChannel,
  setPadKey,
} from "src/redux/slices/mainPage";
import useAuth from "src/hooks/useAuth";
import { makeTimeDuration } from "src/utils";
import { InputField } from "src/components";
import MuiSelect from "src/components/Select/MuiSelect";
import WebRTCPlayer from "src/components/WebRTC";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Button, Divider, Typography } from "@mui/material";
import useMainModal from "src/hooks/useMainModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{ height: "100%" }}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default function MainPage({ dockPanel }) {
  const [tabName, setTabName] = useState(0);
  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const open = Boolean(anchorEl);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const { logout, user } = useAuth();
  const dispatch = useDispatch();
  const {
    channel,
    channels,
    selectedDate,
    defaultChannelData,
    padTableList,
    showTableKey,
  } = useSelector((state) => state.mainPage);
  const showMainModal = useMainModal();

  const handleLogOut = () => {
    setAnchorEl(null);
    logout();
  };

  const handleProfile = useCallback(() => {
    // Show Edit Profile Modal
    showMainModal({
      modalName: "edit-profile-modal",
      status: true,
    });
    handleCloseMenu();
  }, [showMainModal]);

  const refreshWebRTCPlayer = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    const getData = async () => {
      switch (tabName) {
        case 1:
          await dispatch(getPadTableData());
          break;
        case 2:
          await dispatch(getLiveContent());
          break;
        default:
          await dispatch(getProgramTableData(channel, selectedDate));
          break;
      }
      dispatch(getPadTableData());
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabName, channel]);

  useEffect(() => {
    if (Object.keys(padTableList).length > 0) {
      if (showTableKey.length === 0) {
        dispatch(setPadKey([Object.keys(padTableList).sort()[0]]));
      } else {
        dispatch(setPadKey(showTableKey));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [padTableList]);

  return (
    <Fragment>
      <Box className={`left-box${dockPanel === "left" ? " active" : ""}`}>
        <Stack className="left-panel">
          <Box
            sx={{
              minHeight: 168,
              borderRadius: "5px",
              backgroundColor: "#fff",
              px: "10px",
              pt: "10px",
              pb: "4px",
              // Add shadow
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
            }}
          >
            <WebRTCPlayer
              key={refreshKey}
              size={{ height: "auto", width: "100%" }}
              signalingURL={user.SignalingURL}
              applicationName={user.appName}
              streamName={`${channel}${user.overlay ? "_output" : ""}`}
              play={true}
              onRefresh={() => {
                refreshWebRTCPlayer();
              }}
            />
          </Box>
          <Stack spacing={2} className="select-panel" mt={3}>
            <Tabs
              value={tabName}
              onChange={(e, newValue) => {
                setSearch("");
                setTabName(newValue);
              }}
              sx={{
                "&.MuiTabs-root": {
                  minHeight: "100%",
                  padding: "10px",
                  backgroundColor: "#fff",
                  borderRadius: "5px",
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                },
                "& .Mui-selected": {
                  // color: "#FFFFFF !important",
                  // backgroundColor: "#8EC044",
                  borderBottom: "1px solid #8EC044",
                },
                "& .MuiTabs-indicator": {
                  display: "none",
                },
                "& .MuiTabs-flexContainer": {
                  justifyContent: "space-between",
                },
                "& .MuiButtonBase-root": {
                  flex: "1 1 0",
                  fontSize: ".7rem",
                  fontWeight: 800,
                  minHeight: "100%",
                  padding: "5px 10px",
                },
              }}
            >
              <Tab
                label="Programs"
                icon={<CalendarMonthIcon />}
                iconPosition="start"
              />
              <Tab label="My files" icon={<SubscriptionsOutlinedIcon />} iconPosition="start" />
              <Tab label="Live ingest" icon={<QueuePlayNextOutlinedIcon />} iconPosition="start" />
            </Tabs>
            <TabPanel value={tabName} index={0}>
              <ProgramsComponent />
            </TabPanel>
            <TabPanel value={tabName} index={1}>
              <PadTab />
            </TabPanel>
            <TabPanel value={tabName} index={2}>
              <LiveTab />
            </TabPanel>
          </Stack>
        </Stack>
      </Box>
      <Box className="center-box">
        <Stack spacing={3} alignContent="stretch">
          <Stack direction="row" spacing={3} alignItems="center">
            <Box sx={{ flexGrow: 1 }}>
              <InputField
                value={search}
                appened={<SearchIcon />}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Box>
            <MuiSelect
              // Todo change items to all Channels
              items={defaultChannelData}
              value={channel}
              onSelect={(evt) => dispatch(setChannel(evt.target.value))}
              sx={{ flexGrow: 3 }}
            />
            <Button sx={{ color: "black", minWidth: "157px" }} variant="outlined" onClick={handleClickMenu}>
              <MenuOpenOutlinedIcon />
              &nbsp;
              Menu
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleProfile} sx={{ minWidth: 200 }}>
                <ListItemIcon>
                  <PersonOutlineIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="My Profile" />
              </MenuItem>
              <MenuItem onClick={handleLogOut} sx={{ minWidth: 200 }}>
                <ListItemIcon>
                  <LogoutOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </MenuItem>
            </Menu>
          </Stack>
          <Stack direction={{ xs: "column", lg: "row" }} spacing={3} className="card-items">
            <Box className="card subject" sx={{ px: "3rem" }}>
              <Box>
                <Typography component="span" sx={{ opacity: 0.8 }}>Current Program: </Typography>
                {channels.hasOwnProperty("schedulePlaylist")
                  ? channels.schedulePlaylist.title
                  : defaultChannelData[0].defaultPlaylist.title}
              </Box>
              <Box>
                <Typography component="span" sx={{ opacity: 0.8 }}>Current video: </Typography>
                {channels.hasOwnProperty("scheduleItem")
                  ? `${channels.scheduleItem.title} - ${makeTimeDuration(
                    channels.scheduleItem.duration,
                    2
                  )}
                      `
                  : "Playlist - 00:00"}
              </Box>
            </Box>

            <Box className="card" sx={{ px: "1rem", verticalAlign: "middle" }}>
              <Stack direction="row" spacing={2}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography component="span" sx={{ opacity: 0.8 }}>Start time</Typography>
                  <Typography sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    {makeTimeDuration(
                      channels.hasOwnProperty("schedulePlaylist")
                        ? channels.schedulePlaylist.startTime
                        : defaultChannelData[0].defaultPlaylist.startTime
                    )}
                  </Typography>
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography component="span" sx={{ opacity: 0.8 }}>Ending time</Typography>
                  <Typography sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    {makeTimeDuration(
                      channels.hasOwnProperty("schedulePlaylist")
                        ? channels.schedulePlaylist.stopTime
                        : defaultChannelData[0].defaultPlaylist.stopTime
                    )}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Stack>
          {/* DataTable part */}
          <Box className="datatable" flexGrow={1}>
            <TabPanel value={tabName} index={0}>
              <ProgramTabPanel search={search} />
            </TabPanel>
            <TabPanel value={tabName} index={1}>
              <PadTabPanel search={search} />
            </TabPanel>
            <TabPanel value={tabName} index={2}>
              <LiveTabPanel />
            </TabPanel>
          </Box>
        </Stack>
      </Box>
      <Box className={`right-box${dockPanel === "right" ? " active" : ""}`}>
        <RightPanel tabIndex={tabName} />
      </Box>
    </Fragment>
  );
}
